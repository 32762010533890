
import React, { useEffect, useState, useRef } from "react";
import axios from "../../config/axios";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PiDownloadSimple, PiUploadSimple } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { FaEye } from 'react-icons/fa';

import { Button, border } from "@chakra-ui/react";
import { TiAttachment } from "react-icons/ti";
const Referrals = () => {
    const [data, setData] = useState(null);
    const [referrals, setReferrals] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [uploadedFiles2, setUploadedFiles2] = useState({});
    const fileInputRef = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [buttonColor, setButtonColor] = useState("#2CA1C8");
    const [buttonLabel, setButtonLabel] = useState("Send");
    const [buttonStates, setButtonStates] = useState({});
    const [buttonStates1, setButtonStates1] = useState({});
    const [dateInputs, setDateInputs] = useState({});
    const [currentView, setCurrentView] = useState('referrals');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [referralsPerPage] = useState(2);


    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const fetchData = async () => {
        const response = await axios.get("referrals/");
        const referrals = response?.data?.data;
        const matchedPatient = referrals.filter(referral => (
            referral.rf === false
        ));
        setReferrals(matchedPatient);
    };
    useEffect(() => {
        fetchData();
    }, [])



    const handleFileChange = (event, rowId, pending, type) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === "application/pdf") {
            if (selectedFile.size < 10485760) {
                if (type === "f1") {
                    setSelectedFile(selectedFile);
                    setUploadedFiles((prevFiles) => ({
                        ...prevFiles,
                        [rowId]: true,
                    }));
                } else {
                    setSelectedFile2(selectedFile);
                    setUploadedFiles2((prevFiles) => ({
                        ...prevFiles,
                        [rowId]: true,
                    }));
                }


                if (pending === true) {
                    toast.success("PDF file uploaded successfully!");
                } else {
                    toast.success("Another  PDF file uploaded successfully!");
                }
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            } else {
                toast.error("Please ensure that the uploaded file does not exceed 10MB in size.")
            }


        } else {

            toast.error("Please upload a PDF file.");
        }
    };


    const handleUpload = async (patientId, patient) => {
        const file = uploadedFiles[patientId]
        const file2 = uploadedFiles2[patientId]

        if (!file && !file2) {
            toast.error("Please select a file before uploading.");
            return;
        }

        setButtonColor("blue");
        setButtonLabel("Sent");
        setButtonStates(prevStates => ({
            ...prevStates,
            [patientId]: {
                color: "gray",
                label: "Sent"
            }
        }));
        toast.success(`Referral successfully sent back to the referee!`);
        const formData = new FormData();
        formData.append("report1", selectedFile);
        formData.append("report2", selectedFile2);

        try {
            const response = await axios.patch(
                `referrals/report/${patientId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            await new Promise(resolve => setTimeout(resolve, 1000));
            fetchData();

        } catch (error) {
            toast.error(error)
        }
    };

    const markasFinished = async (patientId) => {
        const formData = { pending: false }
        try {
            const response = await axios.patch(
                `referrals/report/${patientId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response) {
                toast.success("This referral has now been marked as finished")
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
            fetchData();
        } catch (error) {
            toast.error(error)
        }
    };

    useEffect(() => {

    // const indexOfLastConsultant = currentPage * referralsPerPage;
    // const indexOfFirstConsultant = indexOfLastConsultant - referralsPerPage;
        const referralData = referrals?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter(referral =>
            (referral.title && referral.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (referral.firstName && referral.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (referral.lastName && referral.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (referral.consultant?.name && referral.consultant.name.toLowerCase().includes(searchTerm.toLowerCase()))
        ).reduce((acc, curr) => {
            const uniqueId = curr.uniqueId
            if (!acc[uniqueId]) {
                acc[uniqueId] = [];
            }
            acc[uniqueId].push(curr);
            return acc;
        }, {})

        // .slice(
        //     indexOfFirstConsultant,
        //     indexOfLastConsultant
        // )
        setData(referralData)
    }, [searchTerm, referrals])
    return (
        <>
            {
                window.innerWidth < 500 ? (
                    <div className="text-center text-white p-4 bg-red-500">
                        Sorry! this portal is only operatable through desktop devices.
                    </div>
                ) : (
                    <div className="container mx-auto w-90">
                        <ToastContainer />
                        <h1 className="text-center text-3xl font-semibold mt-5 mb-2">
                            Received Referrals
                        </h1>
                        <p className="text-center w-4/5 mx-auto mb-3">
                            Here you can see a list of your recent referrals. Click ‘View’ to see the
                            referral in full. If you cannot see the patient you are looking for
                            straight away, try clicking on the column headers to reorder the list.
                            You can also use the search box to find a particular patient.
                        </p>
                        <div className="flex ms-auto me-3 p-2 rounded-lg px-5 my-8">

                        </div>
                        <input
                            type="text"
                            placeholder="Search by  Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="text-base border flex ms-auto me-3 p-2 rounded-lg px-3 my-3"
                        />


                        {/* <DataTable
                            columns={columns}
                            data={referralData}
                            pagination
                            highlightOnHover
                            responsive

                            customStyles={{
                                headRow: {
                                    style: {
                                        borderTop: "1px solid #dee2e6", // Border color for the top of the header row
                                        backgroundColor: "#E5F4FF",
                                    },
                                },
                                headCells: {
                                    style: {
                                        borderRight: "1px solid #dee2e6", // Border color for the right of each header cell
                                    },
                                },
                                rows: {
                                    style: {
                                        borderBottom: "1px solid #dee2e6", // Border color for the bottom of each row
                                        backgroundColor: "gray-200",
                                    },
                                },
                                cells: {
                                    style: {
                                        borderRight: "1px solid #dee2e6",
                                        borderLeft: "1px solid #dee2e6",
                                    },
                                },
                            }}
                        /> */}


                        <div className="flex justify-center text-center mx-8 flex-col gap-2">
                            <table className="min-w-full border border-gray-100 mb-[50px]">
                                <thead>
                                    <tr className="bg-[#EAF6F9]">
                                        <th className="py-2 px-4 border border-[#D0D5DD]">
                                            Patient Name
                                        </th>
                                        <th className="py-2 px-4 border border-[#D0D5DD]">
                                            Consultant Name
                                        </th>
                                        <th className="py-2 px-4 border border-[#D0D5DD]">
                                            Modality
                                        </th>
                                        <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                                            Received Date
                                        </th>

                                        <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                                            <FaEye size={20} />
                                        </th>

                                        <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                                            Upload Report
                                        </th>
                                        <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                                            Send to Consultant
                                        </th>
                                        <th className="py-2 px-4 border border-[#D0D5DD] text-center">
                                            Mark as Finished
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data && Object.keys(data).map((uniqueId, groupIndex) => (
                                        <>
                                            {data[uniqueId].map((referral, index) => (


                                                referral?.pdfURL && (
                                                    <tr key={referral._id} className={`unique-id-${uniqueId}`}
                                                        style={{
                                                            marginBottom: index === 0 ? '4px' : '0px',
                                                            borderLeft: '5px solid #EAF6F9',
                                                            borderRight: '5px solid #EAF6F9',
                                                            borderTop: index === 0 ? '5px solid #EAF6F9' : 'none',
                                                            borderBottom: index === referral?.pdfURL?.length - 1 ? '5px solid #EAF6F9' : 'none',
                                                        }}>
                                                        <td className="border border-[#D0D5DD] text-left gap-2 mx-auto h-auto">
                                                            {referral?.title !== "Prefer Not to Say" && (
                                                                <>
                                                                    {referral?.title + " "}
                                                                </>
                                                            )}
                                                            {referral?.firstName + " " + referral?.lastName}
                                                        </td>
                                                        <td className="border border-[#D0D5DD] w-[150px]">
                                                            {referral?.consultant?.name}
                                                        </td>

                                                        <td className="border border-[#D0D5DD] w-[150px]">
                                                            {referral?.clinicalInfo?.[0]}
                                                        </td>
                                                        <td className="border border-[#D0D5DD] w-[150px]">
                                                            {new Date(referral?.createdAt).toLocaleDateString()}
                                                        </td>

                                                        <td className="border border-[#D0D5DD]">
                                                            <a
                                                                href={referral?.pdfURL ? referral?.pdfURL : "#"}
                                                                download={referral?.pdfURL ? true : false}
                                                                target="_blank"
                                                            >
                                                                <PiDownloadSimple size={30} className="text-[#2CA1C8] text-xl" />{" "}
                                                            </a>
                                                        </td>

                                                        <td className="border border-[#D0D5DD]">
                                                            <div className="flex">
                                                                <input
                                                                    type="file"
                                                                    name="finalReport"
                                                                    multiple
                                                                    onChange={(event) => handleFileChange(event, referral?._id, referral?.pending, "f1")}
                                                                    style={{ display: "none" }}
                                                                    id={`fileInput-${referral?._id}`}
                                                                />
                                                                <label htmlFor={`fileInput-${referral?._id}`}>
                                                                    <TiAttachment
                                                                        size={30}
                                                                        className={`text-xl ${uploadedFiles[referral?._id] || referral?.finalReport1 ? "text-blue-500" : "text-red-500"
                                                                            }`}
                                                                    />
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    name="finalReport1"
                                                                    multiple
                                                                    onChange={(event) => handleFileChange(event, referral?._id, referral?.pending, "f2")}
                                                                    style={{ display: "none" }}
                                                                    id={`fileInput2-${referral?._id}`}
                                                                />
                                                                <label htmlFor={`fileInput2-${referral?._id}`}>
                                                                    <TiAttachment
                                                                        size={30}
                                                                        className={`text-xl ${uploadedFiles2[referral?._id] || referral?.finalReport2 ? "text-blue-500" : "text-red-500"
                                                                            }`}
                                                                    />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 border border-[#D0D5DD] text-center  text-base p-2 text-center items-center justify-center" >
                                                            <button
                                                                className={`text-white ${buttonStates[referral?._id]?.color === "gray" ? "cursor-not-allowed" : "cursor-pointer"}`}
                                                                onClick={() => handleUpload(referral?._id, referral?.name)}
                                                                style={{
                                                                    background: buttonStates[referral?._id]?.color || "#2CA1C8",
                                                                    padding: "10px 30px",
                                                                }}
                                                                disabled={buttonStates[referral?._id]?.color === "gray"}
                                                            >
                                                                {buttonStates[referral?._id]?.label || "Send"}
                                                            </button>
                                                        </td>
                                                        <td
                                                            className="border-[#D0D5DD] text-center text-base p-2 cursor-pointer text-center items-center justify-center ms-auto"

                                                        >
                                                            <button
                                                                className={`text-white ${referral?.pending === false ? "cursor-not-allowed" : "cursor-pointer"}`}
                                                                onClick={() => markasFinished(referral?._id)}
                                                                style={{
                                                                    background: referral?.pending === false ? "grey" : "#2CA1C8",
                                                                    padding: "10px 30px",
                                                                }}
                                                                disabled={referral?.pending === false}
                                                            >
                                                                {"Finished"}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )

                                            ))}
                                            {groupIndex < Object.keys(data).length - 1 && (
                                                <tr key={`spacer-${uniqueId}`} style={{ height: '20px' }}></tr>
                                            )}
                                        </>
                                    ))}
                                </tbody>

                            </table>

                        </div >

                        {/* <div
                            className="pagination-container mx-auto flex items-center justify-center"
                            style={{
                                display: "flex",
                                flexDirection: "column", 
                                alignItems: "center", 
                                marginTop: "20px",
                                border:'1px solid red'
                            }}
                        >

                            <ul
                                className="pagination"
                                style={{
                                    listStyle: "none",
                                    display: "flex",
                                    gap: "10px",
                                    marginBottom: "10px", 
                                }}
                            >
                                {data && Array.from(
                                    {
                                        length: Math.ceil(data?.length / 1),
                                    },
                                    (_, index) => (
                                        <li key={index}>
                                            <button className="hover:transform-scale-110 transition-transform duration-300 mb-[20px]"
                                                onClick={() => paginate(index + 1)}
                                                style={{
                                                    padding: "5px 10px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "5px",
                                                    backgroundColor: "#003942",
                                                    color: "white", // Set text color to white
                                                    cursor: "pointer",
                                                    fontWeight: "600", // Set to semi-bold
                                                    fontSize: "14px", // Adjust font size as needed
                                                }}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    )
                                )}
                                xg
                            </ul>
                        </div> */}

                    </div>
                )}</>
    );
};

export default Referrals;