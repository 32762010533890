//new code
import React from 'react';
import { PiPhoneCallLight } from "react-icons/pi";
import { FaEnvelope } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";

import { HiOutlineMail } from "react-icons/hi";

const Footer = () => {
  return (
    <div className="w-full flex justify-center items-center py-4">
      <div className="bg-white shadow-lg rounded-xl p-4 flex flex-col items-start gap-3 max-w-sm ">
        <p className="text-gray-700 font-semibold text-sm m-auto">Need Assistance?</p>
        <div className="flex items-center gap-2 w-full">
          <IoMdCall className="text-[#2CA1C8]" size={20} />
          <span className="text-gray-800 text-sm">073 5509 4793</span>
          <a href="tel:07355094793" className="ml-[105px]  text-[#2CA1C8] text-xs px-3 py-1 rounded-md border-2 border-[#2CA1C8]  ">Call us</a>
        </div>
        <div className="flex items-center gap-2 w-full">
          <FaEnvelope className="text-[#2CA1C8]" size={20} />
          <span className="text-gray-800 text-sm">clientrelations@umegroup.com</span>
          <a href="mailto:clientrelations@umegroup.com" className="ml-auto  text-[#2CA1C8] text-xs px-3 py-1 rounded-md border-2 border-[#2CA1C8]  ">Email us</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;





//old code
// import React from 'react';

// import { PiPhoneCallLight } from "react-icons/pi";
// const Footer = () => {
//   return (
//     <>
//       <p className='w-full bg-white text-center absolute left-10 bottom-0 flex items-center justify-center gap-2' >
//         <PiPhoneCallLight className='' style={{ width: '23px', height: '23px', color: '#2E90FA' }} />
//         Need assistance? Call us on
//         <a href='tel:07355094793' target="_blank">07355 094 793</a>
//         or
//         <a href='mailto:clientrelations@umegroup.com' target="_blank" className=''>
//           clientrelations@umegroup.com
//         </a>
//       </p>
//     </>
//   );
// }

// export default Footer;
