import React, { useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import { FiEdit } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import axios from "../config/axios";
import { CiUser } from "react-icons/ci";
import { NavLink, useNavigate } from "react-router-dom";

const ITEMS_PER_PAGE = 3;

const AllPatient = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem("data"))?.userData;
      const userId = user?._id;
      const response = await axios.get(`patients/?consultant=${userId}`);
      const patients = response?.data?.data;
      const matchedPatient = patients.filter(patient => (
        patient.consultant._id === user._id
      ));

      const uniqueNames = new Set();
      const uniquePatients = matchedPatient.filter((patient) => {
        const fullName = `${patient?.title} ${patient?.firstName} ${patient?.lastName}`.trim();
        if (!uniqueNames.has(fullName)) {
          uniqueNames.add(fullName);
          return true;
        }
        return false;
      });

      setData(uniquePatients);
    } catch (error) {
      toast(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);


  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate("/patient_list", {
      state: id,
    });
  };

  const handleDelete = async (id) => {
    try {

      const response = await axios.delete(`patients/${id}`);
      if (response.status === 200 || response.status === 204) {

        setData((prevData) =>
          prevData.filter((patient) => patient?._id !== id)
        );


        toast.success("Patient deleted successfully.");
      } else {
        console.log("error===>", response?.data?.message);
        toast(response?.data?.message);
      }
    } catch (error) {

      toast.error(
        error?.response?.data?.message || "Failed to delete patient"
      );
    }
  };

  const handleCreateReferral = (id) => {
    const patient = data?.filter((patient) => patient?._id === id);
    if (localStorage.getItem('rid')) {
      localStorage.removeItem('rid')
    }

    if (patient) {
      navigate("/refer_patient", {
        state: id,
      }
      );
    }

  };


  const handleClick = () => {
    window.location.reload();

    window.location.href = "/patient_list";

  };

  return (
    <>

      {
        window.innerWidth < 500 ? (
          <div className="text-center text-white p-4 bg-red-500">
            Sorry! this portal is only operatable through desktop devices.
          </div>
        ) : (
          <div
            className="w-full text-center justify-center "
            style={{ height: "93vh", backgroundColor: "white" }}
          >
            <ToastContainer />
            <h2 className="mx-auto text-center text-3xl mt-5 pt-3">
              All Patients
            </h2>
            <button
              className="text-base border flex mx-auto p-2 rounded-lg px-3 my-2"
              style={{ background: "#003942", color: "white" }}
              onClick={handleClick}
            >
              <GoPlus className="my-auto" style={{ fontSize: "25px" }} />{" "}
              Create New Patient
            </button>
            <p className="mx-auto text-center">OR</p>

            <p
              className="text-base  flex p-2 cursor-default rounded-lg px-3 mb-4  mx-auto text-center justify-center"
              style={{ color: "black", fontSize: "25px" }}
            >
              Select Patient From the List Below
            </p>


            <div className="text-base mx-auto cursor-default rounded-lg max-w-screen-xl ms-[180px] sm:ms-[10px] md:ms-[200px]">
              <div className="max-w-screen-xl w-full  flex" style={{ paddingBottom: "50px" }}>
                <table colSpan="4" className="mx-auto w-full flex-col table-auto border border-gray-100 ">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border border-[#D0D5DD] text-center bg-[#EAF6F9]">
                        Patient Name
                      </th>
                      <th className="py-2 px-4 border border-[#D0D5DD] text-center bg-[#EAF6F9]">
                        Created Date
                      </th>
                      <th className="py-2 px-4 border border-[#D0D5DD] text-center bg-[#EAF6F9]">
                        Edit
                      </th>
                      <th className="py-2 px-4 border border-[#D0D5DD] text-center bg-[#EAF6F9]">
                        Create New Referral
                      </th>

                      <th className="py-4 px-8 border border-transparent border-opacity-0">
                      </th>
                      <th className="py-4 px-8 border border-transparent border-opacity-0">
                      </th>

                    </tr>
                  </thead>

                  <tbody>
                    {data.length === 0 ? (

                      <tr className="no-patients-row border border-transparent border-opacity-40">
                        <td colSpan="4" className="border-1 border-[#D0D5DD] text-center py-4 ">
                          No patients found
                        </td>
                      </tr>
                    ) : (

                      data.map((patient) => (
                        <tr key={patient._id} >
                          <td className="border border-[#D0D5DD] text-left gap-2   mx-auto h-auto">
                            {" "}
                            <span className="flex items-center">
                              <span style={{ marginRight: "10px" }}> <CiUser className="text-xl text-[#2CA1C8]" />{" "}</span>

                              {patient?.title !== "Prefer Not to Say" && (
                                <>
                                  {`${patient?.title} ${patient?.firstName} ${patient?.lastName}`.length > 25
                                    ? `${patient?.title} ${patient?.firstName} ${patient?.lastName}`
                                    : `${patient?.title} ${patient?.firstName} ${patient?.lastName}`}
                                </>
                              )}
                            </span>



                          </td>
                          <td className="py-4 px-4 border border-[#D0D5DD]">
                            {new Date(patient.createdAt).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "2-digit",
                            })}
                          </td>
                          <td className="py-4  px-4 border border-[#D0D5DD]">

                            <FiEdit
                              className="text-xl mx-auto text-[#2CA1C8] cursor-pointer"
                              onClick={() => handleEdit(patient?._id)}
                            />

                          </td>

                          <td className="py-2 px-4 border border-[#D0D5DD]">
                            <button
                              onClick={() => handleCreateReferral(patient._id)}
                              className="bg-[#003942] text-white px-3 py-1 rounded border-0"
                            >
                              Create a Referral
                            </button>
                          </td>


                        </tr>
                      ))
                    )}
                  </tbody>

                </table>
              </div>
            </div>

          </div>
        )}</>
  );
};

export default AllPatient;
