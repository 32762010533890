import React, { useEffect, useState } from "react";
import { IoCallOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Footer2 from "../components/Footer2";
import { toast } from "react-toastify";
import axios from "axios";
import crowdimage from "../Assets/images/crowdsvg.svg";
import image2 from "../Assets/images/Group 11119.png";
import { CiMail } from "react-icons/ci";
import Footer from "../components/Footer";


function WelcomeBanner() {
  const [isFooterVisible, setFooterVisibility] = useState(false);
  const handleIconClick = () => {
    setFooterVisibility(!isFooterVisible);
    console.log("Working");

  };
  const navigate = useNavigate();
  useEffect(() => {
    const id = JSON.parse(sessionStorage.getItem("data"))?.userData._id;
    console.log(id);
    axios
      .get(`/users/${id}`)
      .then((response) => {
        const userData = response.data?.data;
        console.log("response", userData.statusId);

        const name = userData.name;
        console.log(name);

        if (userData.statusId === "inactive") {
          navigate("/");
          setTimeout(() => {
            toast(
              `Dear ${name} your is in inactive. Please contact support for assistance.`
            );
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        // Handle error as needed
      });
  }, []);

  const handleRefer = () => {
    navigate("/all_patients");
  };
  return (
    <>
      <div className="flex items-center justify-center  relative" style={{ height: "93vh" }} >
        {/* Clickable icon for footer */}
        {/* <div className="absolute right-10 bottom-[20px] pl-[5px] hover:shake z-50">
          <img
            src={image2}
            alt="Click Icon"
            onClick={handleIconClick}
            className="cursor-pointer w-[50px]"
          />
        </div> */}

        {/* Main Content */}
        <div className="flex flex-col items-center gap-8 relative pb-44">
          <img
            src={crowdimage}
            alt="Crowd"
            className="w-[55px] mb-0"
          />

          <div className="space-y-4 text-center">
            <h1 className="text-3xl font-bold text-[#344054]">
              Refer a Patient
            </h1>
            <p className="text-gray-600 text-lg">
              Make an online referral to our clinic.<br />
              You can also book your patient in here.
            </p>
          </div>

          <button
            onClick={handleRefer}
            className="bg-[#2CA1C8] text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-[#2285a3] transition-colors"
          >
            Submit New Referral
          </button>
          
        </div>

        {/* Footer Section */}
        <div className="absolute right-12 bottom-[20px] pl-[5px] hover:shake z-50">
          <img
            src={image2}
            alt="Click Icon"
            onClick={handleIconClick}
            className="cursor-pointer w-[50px]"
          />
        </div>
        {/* Footer Section */}
        <div className={`absolute bottom-14 left-96  w-full ${isFooterVisible ? 'slide-in' : ''}`}>
          {isFooterVisible && (
            <div className="flex justify-center">
              <div className="w-full max-w-screen-xl"> {/* Add max width container */}
                <Footer />
              </div>
            </div>
          )}
        </div>
        {/* <div
          className={`absolute right-80  bottom-5 ${isFooterVisible ? 'slide-in' : ''}`}
          
        >
          {isFooterVisible &&

            <Footer />}
        </div> */}
      </div>
    </>
  );
}

export default WelcomeBanner;
