import React, { useEffect, useState } from 'react'
import './sidebar.css'
// import Logo from "../Assets/images/logopng.png";
import Logo from '../Assets/images/logomin.png'
import axios from '../config/axios'
import { toast } from 'react-toastify'

import {
  RiHome4Line,
  RiTeamLine,
  RiCalendar2Line,
  RiFolder2Line,
  RiUserFollowLine,
  RiPlantLine,
  RiStackLine,
  RiUserUnfollowLine
} from 'react-icons/ri'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi/'
import { Sidebar, SubMenu, Menu, MenuItem } from 'react-pro-sidebar'
import '../Assets/css/sidebar.css'
import { useNavigate } from 'react-router-dom'
import Icon from '../Assets/images/Icon.svg'
import Profile from '../Assets/images/Profile.png'
import user from '../Assets/images/avatar.jpeg'
import Messages from '../Assets/images/Messages.png'
import PatientList from '../Assets/images/Patient List.png'
import ReferaalPic from '../Assets/images/Referaal Patient.svg'
import { useLocation } from 'react-router-dom'

const SidebarMenu = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [activeMenuItem, setActiveMenuItem] = useState('')

  const [toggled, setToggled] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')
  const [userName, setUserName] = useState('')
  let role = JSON.parse(sessionStorage.getItem('data')).userData?.role
  const userId = JSON.parse(sessionStorage.getItem('data'))?.userData?._id
  const location = useLocation()

  const fetchData = async () => {
    try {
      const response = await axios.get(`/users/${userId}`)
      const userData = response?.data?.data
      setProfilePicture(userData?.image || '')
      setUserName(userData?.name || '')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const name = JSON.parse(sessionStorage.getItem('data'))?.userData?.name

    fetchData()
  }, [userId])
  const setActiveMenu = param => {
    switch (param) {
      case '/welcome':
        setActiveMenuItem('dashboard')

        break
      case '/all_referrals':
        setActiveMenuItem('all_referrals')
        break
      case '/referals':
        setActiveMenuItem('referals')
        break
      case '/all_patients':
        setActiveMenuItem('refer_patient')

        break
      case '/patients':
        setActiveMenuItem('patients')

        break
      case '/consultants':
        setActiveMenuItem('consultants')
        break
      case '/patient_list':
        setActiveMenuItem('refer_patient')

        break
      case '/ProfileForm':
        setActiveMenuItem('profile')

        break
      // case "/profile":
      //     setActiveMenuItem("profile");

      //     break;

      default:
        setActiveMenuItem('')
        break
    }
  }

  useEffect(() => {
    setActiveMenu(location.pathname)
  }, [])

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed)
  }

  const handleToggleSidebar = value => {
    setToggled(value)
  }

  const navigate = useNavigate()
  const handleMenuItemClick = async menuItemId => {
    const userId = JSON.parse(sessionStorage.getItem('data'))?.userData?._id
    setActiveMenuItem(menuItemId)
    try {
      // Fetch user data using an asynchronous call
      const response = await axios.get(`/users/${userId}`)
      const userData = response?.data?.data
      if (response?.data?.data?.statusId === 'inactive') {
        console.log('User is inactive. Showing toast...')
        const userName = response?.data?.data?.name
        console.log('User name:', userName)
        navigate('/')
        setTimeout(() => {
          toast(
            `Dear ${userName} your is in inactive. Please contact support for assistance.`
          )
        }, 500)
        return // Exit the function after navigation
      }

      if (userData?.jobTitle === undefined || userData?.jobTitle === '') {
        toast('Please fill out all mandatory fields.')
      } else {
        switch (menuItemId) {
          case 'dashboard':
            navigate('/welcome')
            break
          case 'all_referrals':
            navigate('/all_referrals')
            break
          case 'referals':
            navigate('/referals')
            break
          case 'refer_patient':
            navigate('/all_patients')
            break
          case 'patient_list':
            navigate('/all_patients')
            break
          case 'patients':
            navigate('/patients')
            break
          case 'consultants':
            navigate('/consultants')
            break
          case 'multistep':
            navigate('/patient_list')
            break
          case 'profile':
            navigate('/profile')
            break

          default:
            break
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
      // Handle the error as needed
      toast('Error fetching user data. Please try again in a few moments.')
    }
  }

  const getMenuItemClass = menuItemId => {
    return activeMenuItem === menuItemId ? 'active' : ''
  }

  return (
    <div className='h-full' style={{ zIndex: 300 }}>
      <Sidebar
        className={`h-full ${toggled ? 'toggled' : ''}`}
        style={{
          height: '100%',
          background: '#003942',
          fontSize: toggled ? '0px' : 'initial'
        }}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <main>
          <Menu
            menuItemStyles={{
              button: {
                [`&.active`]: {
                  backgroundColor: 'red',
                  color: '#003942',
                  fontWeight: 'bold'
                }
              }
            }}
          >
            {collapsed ? (
              <MenuItem
                icon={<FiChevronsRight />}
                style={{
                  background: '#F9FAFB',
                  color: 'black'
                }}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<FiChevronsLeft />}
                style={{
                  background: '#F9FAFB',
                  color: 'black'
                }}
                onClick={handleCollapsedChange}
              >
                <div
                  style={{
                    padding: '9px',
                    fontWeight: 'bold',
                    fontSize: 14,
                    letterSpacing: '1px'
                  }}
                >
                  <img src={Logo} alt='logo' width='166px' height='40px' />
                </div>
              </MenuItem>
            )}
            <hr />
          </Menu>
          <div className='my-3 pt-4'>
            <div>
              <img
                src={profilePicture ? profilePicture : user}
                alt='profile'
                className={`rounded-full mx-auto overflow-hidden rounded-full aspect-w-1 aspect-h-1 ${
                  !collapsed
                    ? 'w-[90px] h-[90px] object-cover'
                    : 'w-[70px] h-[70px] object-cover'
                }`}
              />

              <div className='my-3'>
                <div
                  style={{
                    padding: '9px',
                    paddingBottom: '0px',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    letterSpacing: '1px'
                  }}
                >
                  {!collapsed && (
                    <>
                      {!toggled && (
                        <>
                          <p className='text-center text-white pt-0'>
                            {userName !== 'undefined undefined undefined'
                              ? userName
                              : ' '}
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Menu
            className='custom-menu'
            // menuItemStyles={{
            //     root: {
            //         "&.active": {
            //             backgroundColor: "#2CA1C8 !important",
            //             color: "white !important",
            //             fontWeight: "bold",
            //             // padding: "10px",
            //             borderRadius: "8px",
            //             // marginLeft: "5px",
            //             // marginRight: "5px",
            //             "& svg": {
            //                 fill: "white",
            //                 stroke: "white",
            //                 // transform: "scale(1.1)",
            //                 transition: "transform 0.4s",
            //             },
            //         },
            //         "&:hover": {
            //             backgroundColor: "#2CA1C8 !important",
            //             color: "white !important",
            //             fontWeight: "bold",
            //             // padding: "10px",
            //             borderRadius: "8px",
            //             "& svg": {
            //                 fill: "white",
            //                 stroke: "white",
            //                 // transform: "scale(1.1)",
            //                 // transition: "transform 0.4s",
            //             },
            //         },
            //     },
            // }}
            menuItemStyles={{
              root: {
                '&.active': {
                  backgroundColor: '#2CA1C8 !important',
                  color: 'white !important',
                  // fontWeight: "bold",
                  borderRadius: '8px',
                  '& *': { color: 'white !important' }, // Ensures all child elements stay white
                  '& svg': {
                    fill: 'white !important',
                    stroke: 'white !important'
                  }
                },
                '&:hover': {
                  backgroundColor: '#2CA1C8 !important',
                  color: 'white !important',
                  // fontWeight: "bold",
                  borderRadius: '8px',
                  '& *': { color: 'white !important' }, // Ensures all child elements stay white
                  '& svg': {
                    fill: 'white !important',
                    stroke: 'white !important'
                  }
                }
              }
            }}
          >
            <MenuItem
              id='Home'
              onClick={() =>
                handleMenuItemClick(
                  role === 'Admin' ? 'consultants' : 'dashboard'
                )
              }
              className={`group ${getMenuItemClass(
                role === 'Admin' ? 'consultants' : 'dashboard'
              )}`}
              style={{
                color: '#ffffff',
                marginTop: '10px',
                backgroundColor: 'transparent'
                // transition: "all 0.3s",
              }}
              icon={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='ms-2'
                >
                  <g id='Path_7587' data-name='Path 7587'>
                    <path
                      className='cls-2'
                      d='M6.5 18.3334V10.0001H11.5V18.3334M1.5 7.50008L9 1.66675L16.5 7.50008V16.6667C16.5 17.1088 16.3244 17.5327 16.0118 17.8453C15.6993 18.1578 15.2754 18.3334 14.8333 18.3334H3.16667C2.72464 18.3334 2.30072 18.1578 1.98816 17.8453C1.67559 17.5327 1.5 17.1088 1.5 16.6667V7.50008Z'
                      stroke='#2CA1C8'
                      strokeWidth='1.67'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                </svg>
              }
            >
              {role === 'Admin' ? 'Consultants' : 'Refer Patient'}
            </MenuItem>

            <MenuItem
              onClick={() =>
                handleMenuItemClick(
                  role === 'Admin' ? 'patients' : 'patient_list'
                )
              }
              className={`hover:text-black hover:font-normal ${getMenuItemClass(
                role === 'Admin' ? 'patients' : 'patient_list'
              )}`}
              style={{
                color: '#ffffff',
                marginTop: '10px',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: 'grey'
                }
              }}
              icon={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='ms-2'
                >
                  <g id='Path_7587' data-name='Path 7587'>
                    <path
                      className='cls-2'
                      d='M14.1663 16.5V14.8333C14.1663 13.9493 13.8152 13.1014 13.19 12.4763C12.5649 11.8512 11.7171 11.5 10.833 11.5H4.16634C3.28229 11.5 2.43444 11.8512 1.80932 12.4763C1.1842 13.1014 0.833008 13.9493 0.833008 14.8333V16.5M19.1663 16.5V14.8333C19.1658 14.0948 18.92 13.3773 18.4675 12.7936C18.015 12.2099 17.3814 11.793 16.6663 11.6083M13.333 1.60833C14.05 1.79192 14.6855 2.20892 15.1394 2.79359C15.5932 3.37827 15.8395 4.09736 15.8395 4.8375C15.8395 5.57764 15.5932 6.29673 15.1394 6.88141C14.6855 7.46608 14.05 7.88308 13.333 8.06667M10.833 4.83333C10.833 6.67428 9.34062 8.16667 7.49967 8.16667C5.65872 8.16667 4.16634 6.67428 4.16634 4.83333C4.16634 2.99238 5.65872 1.5 7.49967 1.5C9.34062 1.5 10.833 2.99238 10.833 4.83333Z'
                      stroke='#2CA1C8'
                      stroke-width='1.67'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </g>
                </svg>
              }
            >
              {role === 'Admin' ? 'Patients' : 'Patients List'}
            </MenuItem>

            <MenuItem
              onClick={() =>
                handleMenuItemClick(
                  role === 'Admin' ? 'referals' : 'all_referrals'
                )
              }
              className={`hover:text-black !important ${getMenuItemClass(
                role === 'Admin' ? 'referals' : 'all_referrals'
              )}`}
              style={{
                color: '#ffffff',
                marginTop: '10px',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: 'grey'
                }
              }}
              icon={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='ms-2'
                >
                  <g id='Path_7587' data-name='Path 7587'>
                    <path
                      className='cls-2'
                      d='M16.5 11.5C16.5 11.942 16.3244 12.366 16.0118 12.6785C15.6993 12.9911 15.2754 13.1667 14.8333 13.1667H4.83333L1.5 16.5V3.16667C1.5 2.72464 1.67559 2.30072 1.98816 1.98816C2.30072 1.67559 2.72464 1.5 3.16667 1.5H14.8333C15.2754 1.5 15.6993 1.67559 16.0118 1.98816C16.3244 2.30072 16.5 2.72464 16.5 3.16667V11.5Z'
                      stroke='#2CA1C8'
                      stroke-width='1.67'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </g>
                </svg>
              }
            >
              {role === 'Admin' ? 'All Referrals' : 'My Referrals'}
            </MenuItem>

            <MenuItem
              onClick={() => handleMenuItemClick('profile')}
              className={`hover: text-black !important ${getMenuItemClass(
                'profile'
              )}`}
              style={{
                color: '#ffffff',
                marginTop: '10px',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: 'grey'
                }
              }}
              icon={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='ms-2'
                >
                  <g id='Path_7587' data-name='Path 7587'>
                    <path
                      className='cls-2'
                      d='M16.6663 17.5V15.8333C16.6663 14.9493 16.3152 14.1014 15.69 13.4763C15.0649 12.8512 14.2171 12.5 13.333 12.5H6.66634C5.78229 12.5 4.93444 12.8512 4.30932 13.4763C3.6842 14.1014 3.33301 14.9493 3.33301 15.8333V17.5M13.333 5.83333C13.333 7.67428 11.8406 9.16667 9.99967 9.16667C8.15873 9.16667 6.66634 7.67428 6.66634 5.83333C6.66634 3.99238 8.15873 2.5 9.99967 2.5C11.8406 2.5 13.333 3.99238 13.333 5.83333Z'
                      stroke='#2CA1C8'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </g>
                </svg>
              }
            >
              My Profile
            </MenuItem>
          </Menu>
        </main>
      </Sidebar>
    </div>
  )
}

export default SidebarMenu
