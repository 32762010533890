import { useState, useEffect } from 'react'
import profileImage from '../Assets/images/Frame 11123.png'
import user from '../Assets/images/avatar.jpeg'
import eye from '../Assets/images/tabler_eye-filled.png'
import { Switch } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { m } from 'framer-motion'

const ProfileForm = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    title: '',
    firstname: '',
    lastname: '',
    primaryPracticeName: '',
    jobTitle: '',
    postcode: '',
    landline: '',
    email: '',
    registrationNumber: '',
    townCity: '',
    street: '',
    secretaryName: '',
    secretaryTelephone: '',
    secretaryEmail: '',
    password: '',
    allocateOption: 'specify',
    radiologists: [''],
    packages: [],
    blockSecretary: false,
    role: '',
    statusId: '',
    _id: '',
    name: '',
    preferred_radiologists: '',
    preferred_radiologists_list: ['']
  })
  const addPackage = () => {
    setFormData(prev => ({
      ...prev,
      packages: [...(prev.packages || []), { title: '', description: '' }]
    }));
  };
  
  const [showSecretaryName, setShowSecretaryName] = useState(false)
  const [showSecretaryPhone, setShowSecretaryPhone] = useState(false)
  const [showSecretaryEmail, setShowSecretaryEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)
  useEffect(() => {
    if (state) {
      if (state?.consultant?.statusId === 'inactive') {
        setIsBlocked(true)
      }
      setFormData(prev => ({
        ...prev,
        ...state.consultant,
        blockSecretary: state.consultant.statusId === 'inactive',
        radiologists: state.consultant.preferred_radiologists_list?.length
          ? [...state.consultant.preferred_radiologists_list]
          : [''],
        preferred_radiologists_list: state.consultant
          .preferred_radiologists_list?.length
          ? [...state.consultant.preferred_radiologists_list]
          : [''],
        password: '',
        role: 'Admin'
      }))
    } else {
      const userId = JSON.parse(sessionStorage.getItem('data'))?.userData?._id
      const role = JSON.parse(sessionStorage.getItem('data'))?.userData?.role

      setFormData(prev => ({
        ...prev,
        role,
        _id: userId
      }))

      if (userId) {
        axios
          .get(`/users/${userId}`)
          .then(response => {
            const userData = response.data?.data
            setFormData(prev => ({
              ...prev,
              ...userData,
              phone: userData.landline,
              secretaryTelephone: userData.secretaryTelephone,
              radiologists: userData.preferred_radiologists_list?.length
                ? [...userData.preferred_radiologists_list]
                : [''],
              preferred_radiologists_list: userData.preferred_radiologists_list
                ?.length
                ? [...userData.preferred_radiologists_list]
                : [''],
              password: ''
            }))

            if (userData.statusId === 'inactive') {
              navigate('/')
              setTimeout(() => {
                toast(
                  `Dear ${userData.name}, your account is inactive. Please contact support for assistance.`
                )
              }, 500)
            }
          })
          .catch(error => {
            console.error('Error fetching user data:', error)
          })
      }
    }
  }, [state, navigate])

  const toggleBlockStatus = () => {
    setFormData(prev => {
      const newStatus = prev.statusId === 'active' ? 'inactive' : 'active'
      return {
        ...prev,
        statusId: newStatus,
        blockSecretary: newStatus === 'inactive'
      }
    })
    setIsBlocked(!isBlocked)

    toast(
      formData.statusId === 'active'
        ? `Please update unblocking ${formData.title} ${formData.firstname} ${formData.lastname} by saving this page.`
        : `Please update unblocking ${formData.title} ${formData.firstname} ${formData.lastname} by saving this page.`
    )
  }
  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))

    // Special handling for radiologist allocation change
    if (name === 'preferred_radiologists') {
      setFormData(prev => ({
        ...prev,
        radiologists: value === 'Allow_UME_to_allocate_for_me' ? [] : [''],
        preferred_radiologists_list:
          value === 'Allow_UME_to_allocate_for_me' ? [] : ['']
      }))
    }
  }

  const [imagePreview, setImagePreview] = useState(null)

  const handleImageChange = e => {
    const file = e.target.files[0]
    if (file) {
      // Preview the image locally
      setImagePreview(URL.createObjectURL(file))
      // Update formData with the File object for upload
      setFormData(prev => ({ ...prev, image: file }))
    }
  }

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log('Form Data:', formData);
  // };
  const handleSubmit = async e => {
    e.preventDefault()
    console.log("formData",formData)

    const role = JSON.parse(sessionStorage.getItem('data'))?.userData?.role
    const userId = formData._id
    if (formData.role === 'consultant') {
      if (!formData.password || formData.password === '') {
        toast('Please fill out all required fields to continue.')
        return
      } else if (formData.password.length < 8) {
        toast('Password should not be less than 8 characters.')
        return
      }
    }

    const requiredFields = [
      'title',
      'firstname',
      'jobTitle',
      'lastname',
      'email',
      'registrationNumber',
      'secretaryEmail',
      'secretaryTelephone',
      'secretaryName'
    ]

    for (const field of requiredFields) {
      if (!formData[field] || formData[field] === '') {
        toast(`Please fill out ${formData[field]} fields to continue.`)
        return
      }
    }

    try {
      const submitData = {
        ...formData,
        name:
          formData.title === 'Prefer Not to Say'
            ? `${formData.firstname} ${formData.lastname}`
            : `${formData.title} ${formData.firstname} ${formData.lastname}`,
        check: formData.title !== 'Prefer Not to Say',
        // landline: formData.phone,
        secretaryTelephone: formData.secretaryTelephone,
        preferred_radiologists_list: formData.radiologists
      }

      // delete submitData.phone
      // delete submitData.secretaryTelephone
      // delete submitData.radiologists
      delete submitData.role

      if (formData.role === 'consultant') {
        const passwordCheck = await axios.post('/users/verifyPassword', {
          id: userId,
          password: formData.password
        })

        if (passwordCheck?.status !== 200) {
          toast.error('Input password is incorrect.')
          return
        }
      }
      const response = await axios.patch(`/users/${userId}`, submitData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      if (response?.status === 200 || response?.status === 201) {
        toast.success(response.data.message)
        const sessionData = JSON.parse(sessionStorage.getItem('data'))
        if (formData.role === 'consultant' && sessionData) {
          sessionStorage.setItem(
            'data',
            JSON.stringify({
              ...sessionData,
              userData: response.data?.data?.userData || formData
            })
          )
        }
      console.log("formdata:",submitData)

        // Redirect based on role
        const redirectPath =
          formData.role === 'consultant' ? '/welcome' : '/consultants'
        navigate(redirectPath)
      } else {
        toast('Something went wrong. Please try again.')
      }
    } catch (error) {
      console.error('Error saving profile:', error)
      toast.error(
        error.response?.data?.message ||
          'An error occurred while saving your profile.'
      )
    }
  }

  return (
    <div
    //   className='max-w-full mx-auto px-48 py-6 bg-[#f9fafb]' //updated
      // 'max-w-4xl mx-auto p-6 bg-[#f9fafb]'  //original
      className= 'max-w-full mx-auto px-6 py-6 bg-[#f9fafb]'  //figma
    >
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleSubmit}>
        {/* Header Section */}
        <div className='flex justify-between items-center mb-8'>
          <h1 className='text-3xl font-bold'>My Profile</h1>
          <button
            type='submit'
            className='bg-[#2CA1C8] text-white px-6 py-2 border border-2 border-[#2CA1C8] rounded hover:bg-white hover:text-[#2CA1C8] hover:border-2 hover:border-[#2CA1C8]'
          >
            Save
          </button>
        </div>

        {/* Account Information Section */}
        <div className='mb-8 bg-[#ffffff] p-5 rounded-xl'>
          <h2 className='text-xl font-bold mb-4'>Account Information</h2>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            {/* Title */}
            <div>
              <label className='block text-lg font-medium mb-1'>
                Title<span className='text-red-600'>*</span>
              </label>
              <select
                className='w-full p-2 border rounded'
                value={formData.title}
                onChange={e =>
                  setFormData({ ...formData, title: e.target.value })
               }
              >
                <option value='' disabled>
                  Title
                </option>
                <option value='Mr'>Mr</option>
                <option value='Miss'>Miss</option>
                <option value='Mrs'>Mrs</option>
                <option value='Ms'>Ms</option>
                <option value='Dr'>Dr</option>
                <option value='Prof'>Prof</option>
                <option value='Dame'>Dame</option>
                <option value='Lady'>Lady</option>
                <option value='Lord'>Lord</option>
                <option value='Sir'>Sir</option>

                <option value='Prefer Not to Say'>Prefer Not to Say</option>
              </select>
            </div>

            {/* First Name */}
            <div>
              <label className='block text-lg font-medium mb-1'>
                First Name
                <span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder='First Name'
                value={formData.firstname}
                onChange={e =>
                  setFormData({ ...formData, firstname: e.target.value })
                }
                // onChange={handleInputChange}
              />
            </div>

            {/* Last Name */}
            <div>
              <label className='block text-lg font-medium mb-1'>
                Last Name<span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder='Last Name'
                value={formData.lastname}
                onChange={e =>
                  setFormData({ ...formData, lastname: e.target.value })
                }
                // onChange={handleInputChange}
              />
            </div>

            {/* Primary Practice Name */}
            <div>
              <label className='block text-lg font-medium mb-1'>
                Primary Practice Name
              </label>
              <input
                type='text'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder='Primary Practice Name'
                value={formData.primaryPracticeName}
                onChange={e =>
                  setFormData({
                    ...formData,
                    primaryPracticeName: e.target.value
                  })
                }
                // onChange={handleInputChange}
              />
            </div>

            {/* Job Title */}
            <div>
              <label className='block text-lg font-medium mb-1'>
                Job Title
                <span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder='Job Title'
                value={formData.jobTitle}
                onChange={e =>
                  setFormData({ ...formData, jobTitle: e.target.value })
                }
                // onChange={handleInputChange}
              />
            </div>

            {/* Postcode */}
            <div>
              <label className='block text-lg font-medium mb-1'>Postcode</label>
              <input
                type='text'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder='Postcode'
                value={formData.postcode}
                onChange={e =>
                  setFormData({ ...formData, postcode: e.target.value })
                }
                // onChange={handleInputChange}
              />
            </div>

            <div>
              <label className='block text-lg font-medium mb-1'>
                Number Phone
                <span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder="Referrer's mobile"
                value={formData.landline}
                onChange={e =>
                  setFormData({ ...formData, landline: e.target.value })
                }
                // onChange={handleInputChange}
              />
            </div>

            {/* Email */}
            <div>
              <label className='block text-lg font-medium mb-1'>
                E-mail
                <span className='text-red-600'>*</span>
              </label>
              <input
                type='email'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder="Referrer's email"
                value={formData.email}
                onChange={e =>
                  setFormData({ ...formData, email: e.target.value })
                }
                // onChange={handleInputChange}
              />
            </div>

            <div>
              <label className='block text-lg font-medium mb-1'>
                GMC/AHP Registration
                <span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                placeholder='GMC/AHP Registration Number'
                value={formData.registrationNumber}
                onChange={e =>
                  setFormData({
                    ...formData,
                    registrationNumber: e.target.value
                  })
                }
                // onChange={handleInputChange}
              />
            </div>
            {/* City/Town + Street */}
            <div className='md:col-span-2'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div>
                  <label className='block text-lg font-medium mb-1'>
                    City/Town
                  </label>
                  <input
                    type='text'
                    className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                    placeholder='Town/City'
                    value={formData.townCity}
                    onChange={e =>
                      setFormData({ ...formData, townCity: e.target.value })
                    }
                    // onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label className='block text-lg font-medium mb-1'>
                    Street
                  </label>
                  <input
                    type='text'
                    className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
                    placeholder='Street'
                    value={formData.street}
                    onChange={e =>
                      setFormData({ ...formData, street: e.target.value })
                    }
                    // onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Secretary Information Section */}
        <div className='mb-8 bg-[#ffffff] p-5 rounded-xl'>
          <h2 className='text-xl font-bold mb-4'>Secretary Information</h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            {/* Column 1 */}
            <div className='space-y-4'>
              {/* Secretary Name */}
              <div>
                <label className='block text-lg font-medium mb-1'>
                  Secretary Name
                  <span className='text-red-600'>*</span>
                </label>
                <div className='relative'>
                  <input
                    // type={showSecretaryName ? 'text' : 'password'}
                    type='text'
                    className='w-full p-2 border border-2 border-[#D0D5DD] rounded pr-10'
                    placeholder='Secretary Name'
                    value={formData.secretaryName}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        secretaryName: e.target.value
                      })
                    }
                  />
                 
                </div>
              </div>

              {/* Secretary Email */}
              <div>
                <label className='block text-lg font-medium mb-1'>
                  Secretary Email
                  <span className='text-red-600'>*</span>
                </label>
                <div className='relative'>
                  <input
                    // type={showSecretaryEmail ? 'text' : 'password'}
                    type='text'
                    className='w-full p-2 border border-2 border-[#D0D5DD] rounded pr-10'
                    placeholder='Secretary Email'
                    value={formData.secretaryEmail}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        secretaryEmail: e.target.value
                      })
                    }
                  />
                </div>
              </div>
            </div>

            {/* Column 2 */}
            <div className='space-y-4'>
              {/* Secretary Phone */}
              <div>
                <label className='block text-lg font-medium mb-1'>
                  Secretary Phone
                  <span className='text-red-600'>*</span>
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    className='w-full p-2 border border-2 border-[#D0D5DD] rounded pr-10'
                    placeholder='Secretary Telephone'
                    value={formData.secretaryTelephone}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        secretaryTelephone: e.target.value
                      })
                    }
                  />
                 
                </div>
              </div>

              {formData.role !== 'consultant' && (
                <>
                  <label className='block text-lg font-medium mb-1'>
                    Want to block this account?
                  </label>
                  <div className='flex items-center gap-4'>
                    <Switch
                      checked={isBlocked}
                      onChange={toggleBlockStatus}
                      style={{
                        backgroundColor: isBlocked
                          ? '#2CA1C8'
                          : '#b3b3b369',
                        border: isBlocked ? '' : ''
                      }}
                    />

                    <span className=' text-[#344054] text-lg font-medium'>
                      {isBlocked
                        ? 'Unblock the account'
                        : 'Block the account'}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Radiologist Allocation Section */}
        <div className='mb-8 bg-white p-6 rounded-xl shadow-sm'>
          <div className='flex row justify-between'>
            <h2 className='text-xl font-bold mb-6'>Radiologist Allocation</h2>

            {/* Radio Button Options */}
            <div className='flex flex-row gap-4 mb-6'>
              <label className='flex items-center gap-3 cursor-pointer'>
                <div
                  className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
             ${
               formData.allocateOption === 'allow'
                 ? 'border-[#2CA1C8]'
                 : 'border-gray-300'
             }`}
                >
                  {formData.allocateOption === 'allow' && (
                    <div className='w-3 h-3 rounded-full bg-[#2CA1C8]'></div>
                  )}
                </div>
                <input
                  type='radio'
                  name='allocation'
                  value='allow'
                  checked={formData.allocateOption === 'allow'}
                  onChange={() =>
                    setFormData({ ...formData, allocateOption: 'allow' })
                  }
                  className='hidden'
                />
                <span className='text-gray-700'>
                  Allow UME to allocate for myself
                </span>
              </label>

              <label className='flex items-center gap-3 cursor-pointer'>
                <div
                  className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
           ${
             formData.allocateOption === 'specify'
               ? 'border-[#2CA1C8]'
               : 'border-gray-300'
           }`}
                >
                  {formData.allocateOption === 'specify' && (
                    <div className='w-3 h-3 rounded-full bg-[#2CA1C8]'></div>
                  )}
                </div>
                <input
                  type='radio'
                  name='allocation'
                  value='specify'
                  checked={formData.allocateOption === 'specify'}
                  onChange={() =>
                    setFormData({ ...formData, allocateOption: 'specify' })
                  }
                  className='hidden'
                />
                <span className='text-gray-700'>Specify by myself</span>
              </label>
            </div>
          </div>

          {/* Radiologist Inputs (shown only when 'specify' is selected) */}
          {formData.allocateOption === 'specify' && (
            <div className='space-y-4 w-1/2'>
              {formData.radiologists.map((name, index) => (
                <div key={index} className='flex items-center gap-3'>
                  <div className='w-full'>
                    <label className='block text-sm font-medium text-gray-700 mb-1'>
                      Radiologist Name
                    </label>
                    <div className='flex items-center gap-2'>
                      <input
                        type='text'
                        className='w-full p-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-[#2CA1C8] focus:border-[#2CA1C8]'
                        placeholder='Add Radiologist name...'
                        value={name}
                        onChange={e => {
                          const newRadiologists = [...formData.radiologists]
                          newRadiologists[index] = e.target.value
                          setFormData({
                            ...formData,
                            radiologists: newRadiologists
                          })
                        }}
                      />
                      {index === formData.radiologists.length - 1 ? (
                        <button
                          type='button'
                          onClick={() =>
                            setFormData({
                              ...formData,
                              radiologists: [...formData.radiologists, '']
                            })
                          }
                          className='flex items-center justify-center w-10 h-10 rounded-full bg-[#EFF8FF] text-[#2CA1C8] hover:bg-[#D0EBFF]'
                        >
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M12 5V19M5 12H19'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          type='button'
                          onClick={() =>
                            setFormData({
                              ...formData,
                              radiologists: formData.radiologists.filter(
                                (_, i) => i !== index
                              )
                            })
                          }
                          className='flex items-center justify-center w-10 h-10 rounded-full bg-[#EFF8FF] text-[#2CA1C8] hover:bg-[#D0EBFF]'
                        >
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                          >
                            <path
                              d='M5 12H19'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Package Section */}
        {formData.role === 'Admin' && (
          <div className='mb-8 bg-[#ffffff] p-5 rounded-xl'>
            <div className='flex items-center justify-between mb-4'>
              <h2 className='text-xl font-bold'>Package Section</h2>
              <button
                type='button'
                // onClick={() =>
                //   setFormData({
                //     ...formData,
                //     packages: [...formData.packages, { title: '', description: '' }]
                //   })
                // }
                onClick={addPackage}
                className='flex items-center justify-center w-10 h-10 rounded-full bg-[#ddf0f6] text-[#2CA1C8] hover:text-[#1a708a]'
              >
                {/* Plus icon */}
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12 5V19M5 12H19'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </button>
            </div>

            {formData.packages.map((pkg, index) => (
  <div key={index} className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
    <div>
      <input
        type='text'
        className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
        placeholder='Title'
        value={pkg.title || ''}
        onChange={e => {
          const newPackages = [...formData.packages];
          newPackages[index].title = e.target.value;
          setFormData({ ...formData, packages: newPackages });
        }}
      />
    </div>
    <div className='flex items-center gap-2'>
      <div className='flex-1'>
        <input
          type='text'
          className='w-full p-2 border border-2 border-[#D0D5DD] rounded'
          placeholder='Description'
          value={pkg.description || ''}
          onChange={e => {
            const newPackages = [...formData.packages];
            newPackages[index].description = e.target.value;
            setFormData({ ...formData, packages: newPackages });
          }}
        />
      </div>
      <button
        type='button'
        onClick={() =>
          setFormData({
            ...formData,
            packages: formData.packages.filter((_, i) => i !== index)
          })
        }
        className='flex items-center justify-center w-10 h-10 rounded-full bg-[#ddf0f6] text-[#2CA1C8] hover:text-[#1a708a]'
      >
        {/* Minus icon */}
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path d='M5 12H19' stroke='currentColor' strokeWidth='2' />
        </svg>
      </button>
    </div>
  </div>
))}
          </div>
        )}

        {/* Profile Settings Section */}
        <div className='mb-8 bg-[#ffffff] p-5 rounded-xl'>
          <h2 className='text-xl font-bold mb-6'>Profile settings</h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            {/* Change Photo Profile Column */}
            <div>
              <div className='mb-4'>
                <h3 className='font-medium mb-4'>Change Photo Profile</h3>
                <div className='flex gap-4 items-start'>
                  <img
                    src={imagePreview || formData.image || user}
                    alt='Profile'
                    className='w-[80px] h-[80px] rounded-full object-cover'
                    // className="w-24 h-24 rounded-full object-cover"
                    // className="w-24 h-24 rounded-full object-center"
                  />

                  {/* Upload Section */}
                  <div className='flex-1'>
                    <label className='block text-sm font-medium mb-2'>
                      Upload new photo
                    </label>
                    <input
                      type='file'
                      id='photo-upload'
                      className='hidden'
                      accept='image/jpeg, image/png'
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor='photo-upload'
                      className='inline-block text-[#667085] bg-white border border-2 border-gray-300 px-4 py-2 rounded text-sm  cursor-pointer mb-2'
                    >
                      Only JPEG and PNG formats allowed
                    </label><br />
                    {/* <p className="text-sm text-gray-500">JPEG or PNG, max 2MB</p> */}
                    <button className='bg-[#ffffff] font-semibold text-base text-[#2CA1C8] px-6 py-2 mt-5 border border-2 border-[#2CA1C8] rounded hover:bg-[#2CA1C8] hover:text-white hover:border-2 hover:border-[#2CA1C8]'>
                      Save New Photo
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {formData.role === 'consultant' && (
              <div>
                <h3 className='font-medium mb-4'>Account Password</h3>
                <div className='space-y-4'>
                  <div>
                    <label className='block text-sm font-medium mb-1'>
                      Password
                      <span className='text-red-600'>*</span>
                    </label>
                    <div className='relative'>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className='w-full p-2 border border-2 border-[#D0D5DD] rounded pr-10'
                        placeholder='Enter current password'
                        value={formData.password}
                        onChange={e =>
                          setFormData({ ...formData, password: e.target.value })
                        }
                      />
                      <img
                        src={eye}
                        alt='Toggle visibility'
                        className='absolute right-3 top-3 cursor-pointer h-5 w-5'
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default ProfileForm
