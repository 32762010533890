// MultiStepForm.js

import React, { useEffect, useState } from 'react'
import axios from '../config/axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Textarea, Select } from '@chakra-ui/react'
import Lottie from 'lottie-react'
import FormData from 'form-data'
import { Modality, steps, formatDate } from '../utils/seed'
import { getUser, generatePdfFiles } from '../utils/generatePdf'
import {
  createReferalObject,
  validateSteps,
  isInvalidFormData
} from '../utils/Utils'
import animationData from '../Assets/loader.json'

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [formData, setFormData] = useState('')
  const [errors, setErrors] = useState({})
  const [id, setId] = useState('')
  const [rid, setRid] = useState('')
  const [loading, setLoading] = useState(false)
  const [pdfurls, setpdfUrls] = useState(null)
  const [referralStatus, setReferralStatus] = useState(false)
  const [Referral, setReferrlchange] = useState()
  const [userData, setUserData] = useState({})
  const [checkboxes, setCheckboxes] = useState(Modality)
  const [clinicalData, setclinicalData] = useState([])
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedPackage, setSelectedPackage] = useState(null)

  const handlePackageChange = event => {
    setSelectedPackage(event.target.value)
  }

  useEffect(() => {
    getUser()
    setId(location?.state || '')
  }, [location])

  const fetchData = async () => {
    try {
      if (id) {
        try {
          const patientResponse = await axios.get(`patients/${id}`)
          const patientData = patientResponse?.data?.data
          setFormData(patientData || {})
          return
        } catch (patientError) {
          console.error('Error fetching patient data:', patientError)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchd = async () => {
    const userId = JSON.parse(sessionStorage.getItem('data'))?.userData?._id
    const response = await axios.get(`/users/${userId}`)
    const userData = response?.data?.data
    setUserData(userData)
  }

  const fetchReferralData = async () => {
    try {
      let referral_id = localStorage.getItem('rid')
      if (referral_id) {
        try {
          const referralResponse = await axios.get(`referrals/${referral_id}`)
          const referralData = referralResponse?.data?.data
          setReferralStatus(referralData.rf)
          setFormData(referralData || {})
        } catch (referralError) {
          console.error('Error fetching referral data:', referralError)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchReferralData()
    fetchData()
    fetchd()
  }, [id])

  const handleInputChange = e => {
    const { name, value, type, checked } = e.target
    localStorage.setItem(name, value)
    setFormData(prevData => ({
      ...prevData,
      [name]:
        type === 'checkbox'
          ? handleCheckboxChange(name, value, checked, prevData)
          : value
    }))
  }

  const handleCheckboxChange = (name, value, checked, prevData) => {
    const currentOptions = prevData[name] || []
    if (checked) {
      return [...currentOptions, value]
    } else {
      return currentOptions?.filter(option => option !== value)
    }
  }

  const validateStep = () => {
    return validateSteps(
      formData,
      checkboxes,
      currentStep,
      steps,
      toast,
      selectedPackage
    )
  }

  const updateStep = () => {
    setCurrentStep(prevStep => prevStep + 1)
  }

  const handleNextStep = async () => {
    if (validateStep()) {
      try {
        if (!location.state) {
          toast('Patient has not been found. Please create a patient first.')
          navigate('/patient_list')
        }
        const patientData = {
          ...formData
        }
        if (currentStep === 2) {
          patientData.safety = {
            pregnancy: formData.gender === 'yes' ? 'Yes' : 'No',
            eGFR: formData.eGFR || null
          }
        }
        if (currentStep === 1) {
          patientData.bodyPart = formData.bodyPart
          patientData.clinicalIndication = formData.clinicalIndication
          setLoading(true)
          setTimeout(() => {
            updateStep()
            setLoading(false)
          }, 500)
        }
      } catch (error) {
        toast(
          error?.response?.data?.message ||
            'An error occurred while saving data.'
        )
      }
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep(prevStep => prevStep - 1)
  }

  const handleCancel = () => {
    navigate('/all_patients')
  }

  const handleSubmit = e => {
    e.preventDefault()
  }

  const handleInputTextChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleConfirm = async () => {
    setLoading(true)
    try {
      const data = {
        ...Referral,
        rf: 'false'
      }
      const response1 = await axios.patch(`referrals/${rid} `, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      localStorage.removeItem('rid')
      setTimeout(() => {
        toast(
          'Your referral has been created and a copy has been emailed to you. A confirmation email has also been sent to the patient, our bookings team will be in touch with the patient directly. You can download the PDF referral below or you can find it under your referrals page.'
        )
        updateStep()
        setLoading(false)
      }, 500)
    } catch (error) {
      toast(
        'An error occurred while processing your request. Please try again later.'
      )
    }
  }

  const handleEdit = rid => {
    setCurrentStep(prevStep => prevStep - 2)
    localStorage.setItem('rid', rid)
  }

  const handleSendEmail = async () => {
    try {
      if (isInvalidFormData(formData)) {
        toast.error('Please answer all required questions to proceed.')
      } else {
        setLoading(true)
        const userId = JSON.parse(sessionStorage.getItem('data'))?.userData?._id
        const response = await axios.get(`/users/${userId}`)
        const userData = response?.data?.data
        setUserData(userData)

        if (userData?.jobTitle === '') {
          toast('Please fill out all required fields to continue.')
          navigate('/profile')
        } else {
          const formDatas = new FormData()
          const PdfFiles = await generatePdfFiles(
            checkboxes,
            userData,
            formData,
            formDatas,
            selectedPackage
          )
          const { clinicalInfo } = PdfFiles
          const data = createReferalObject(userData, formData, clinicalInfo)

          if (formData?.date) {
            formDatas.append('date', formData?.date)
          }
          Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((v, index) => {
                formDatas.append(`${key}[${index}]`, v)
              })
            } else {
              formDatas.append(key, value)
            }
          })
          let response1

          if (location?.state) {
            if (referralStatus === true) {
              response1 = await axios.patch(`referrals/${id} `, formDatas, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
            } else {
              response1 = await axios.post('referrals/', formDatas, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
            }

            setReferrlchange(response1.data.data)
            setTimeout(() => {
              updateStep()
              setLoading(false)
            }, 500)
          } else {
            toast('Patient has not been found. Please create a patient first.')
            navigate('/patient_list')
          }
          setRid(response1?.data?.data._id)
          setpdfUrls(response1?.data?.data?.pdfUrls)
          setclinicalData(response1?.data?.data?.clinicalInfo)
          console.log('clinicalData:', clinicalData)
          console.log(
            'response1?.data?.data?.clinicalInfo:',
            response1?.data?.data?.clinicalInfo
          )
        }
      }
    } catch (error) {
      toast('An error occurred while processing the request.')
    }
  }

  const handleCheckboxChanges = id => {
    const updatedCheckboxes = checkboxes.map(checkbox => {
      if (checkbox.id === id) {
        if (checkbox.pairs) {
          return {
            ...checkbox,
            checked: !checkbox.checked,
            pairs: checkbox.checked
              ? [{ value1: '', value2: '' }]
              : checkbox.pairs
          }
        } else {
          return {
            ...checkbox,
            checked: !checkbox.checked
          }
        }
      }
      return checkbox
    })

    setCheckboxes(updatedCheckboxes)
    let setData =
      updatedCheckboxes
        ?.filter(data => data.checked === true)
        ?.map(data => ({
          name: data.name,
          options: data.pairs || []
        })) || []
    setFormData(prevData => ({
      ...prevData,
      ['modality']: [...setData]
    }))
  }

  const handleAddPair = checkboxId => {
    const updatedCheckboxes = checkboxes.map(checkbox =>
      checkbox.id === checkboxId
        ? {
            ...checkbox,
            pairs: [{ value1: '', value2: '' }, ...(checkbox.pairs || [])]
          }
        : checkbox
    )
    setCheckboxes(updatedCheckboxes)
  }

  const handleChange = (checkboxId, pairIndex, field, value) => {
    const updatedCheckboxes = checkboxes.map(checkbox =>
      checkbox.id === checkboxId
        ? {
            ...checkbox,
            pairs: (checkbox.pairs || []).map((pair, index) =>
              index === pairIndex ? { ...pair, [field]: value } : pair
            )
          }
        : checkbox
    )
    setCheckboxes(updatedCheckboxes)
    let setData =
      updatedCheckboxes
        ?.filter(data => data.checked === true)
        ?.map(data => ({
          name: data.name,
          options: data.pairs || []
        })) || []
    setFormData(prevData => ({
      ...prevData,
      ['modality']: [...setData]
    }))
  }

  const handleDeletePair = (checkboxId, pairIndex) => {
    const updatedCheckboxes = checkboxes.map(checkbox =>
      checkbox.id === checkboxId
        ? {
            ...checkbox,
            pairs: (checkbox.pairs || []).filter(
              (pair, index) => index !== pairIndex
            )
          }
        : checkbox
    )
    setCheckboxes(updatedCheckboxes)
  }

  return (
    <>
      {loading ? (
        <>
          <div className='p-36  flex justify-center items-center mt-20'>
            <Lottie animationData={animationData} />
          </div>
        </>
      ) : (
        <div>
          {window.innerWidth < 500 ? (
            <div className='text-center text-white p-4 bg-red-500'>
              Sorry! this portal is only operatable through desktop devices.
            </div>
          ) : (
            <div
              className='   pl-10 pr-10 px-10 pt-10'
              style={{ width: '100%', backgroundColor: '#f9fafb' }}
            >
              {/* style={{ width: '80%', backgroundColor: 'white' }}> */}
              <ToastContainer />
              <div className='mb-4 w-full  '>
                <div className='flex justify-between w-full relative'>
                  {steps.map((step, index) => (
                    <div
                      key={step.id}
                      className='flex flex-col items-center w-full relative z-10'
                    >
                      {/* Connecting line (always visible) */}
                      {index > 0 && (
                        <div className='absolute h-[2px] w-full top-[7px] left-[-47%] -z-10'>
                          {/* Grey line (background for all incomplete steps) */}
                          <div className='h-full bg-[#BDBDBD] w-full'></div>

                          {/* Blue line overlay (for completed steps) */}
                          <div
                            className={`h-full absolute top-0 left-0 bg-[#2CA1C8] transition-all duration-300`}
                            style={{
                              width: currentStep >= step.id ? '100%' : '0%'
                            }}
                          ></div>
                        </div>
                      )}

                      {/* Step dot */}
                      <div
                        className={`w-4 h-4 rounded-full transition-colors duration-300
        ${currentStep >= step.id ? 'bg-[#2CA1C8]' : 'bg-[#BDBDBD]'}`}
                      ></div>

                      {/* Step label */}
                      <span
                        className={`mt-3 text-sm ${
                          currentStep >= step.id
                            ? 'text-[#2CA1C8] font-medium'
                            : 'text-gray-500'
                        }`}
                      >
                        {step.stepName}
                      </span>
                    </div>
                  ))}
                </div>
                {/* <div className='flex space-x-2 w-full'>
                  {steps.map((step, index) => (
                    <React.Fragment key={step.id}>
                      {index > 0 && (
                        <div
                          className='flex items-center'
                          style={{ flex: '0.6 1' }}
                        >
                          <div className='flex-1 h-0.5 bg-gray-300'></div>
                        </div>
                      )}
                      <div
                        className={`flex items-center justify-between ps-2  py-1 border -black rounded-full ${step.id === currentStep
                            ? 'font-lg hover:scale-105 transition-transform duration-300 transform origin-center'
                            : 'opacity-50 cursor-not-allowed'
                          } `}
                      >
                        <span
                          className={`border-2 text-white font-bold rounded-full p-1 px-3 ${step.id === currentStep
                              ? 'bg-[#2CA1C8]'
                              : 'bg-[#D0D5DD]'
                            } `}
                        >
                          {step.id}
                        </span>{' '}
                        <span
                          className='text-clip  inline-flex justify-end px-4'
                          style={{ color: '#101828', fontSize: '14px' }}
                        >
                          {step?.stepName}

                        </span>
                      </div>
                    </React.Fragment>
                  ))}
                </div> */}
              </div>
              <form onSubmit={handleSubmit}>
                {steps.map((step, index) => (
                  <div
                    key={step.id}
                    className={currentStep === step.id ? '' : 'hidden'}
                  >
                    <div className='flex justify-between items-center mb-4'>
                      {currentStep !== steps.length && (
                        <h2 className='text-2xl font-bold'>{step.stepName}</h2>
                      )}

                      {/* Navigation Buttons */}
                      {currentStep !== steps.length && (
                        <div className='flex gap-4'>
                          {/* Previous Button - Show only if not on first step */}
                          {currentStep > 1 && (
                            <button
                              type='button'
                              onClick={handlePreviousStep}
                              className='flex px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors items-center'
                            >
                              <svg
                                className='w-5 h-5 mr-3'
                                fill='none'
                                stroke='currentColor'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M10 19l-7-7m0 0l7-7m-7 7h18'
                                ></path>
                              </svg>
                              Previous
                            </button>
                          )}

                          {/* Continue Button - Different actions based on step */}
                          <button
                            type='button'
                            onClick={
                              currentStep === 1
                                ? handleNextStep
                                : currentStep === 2
                                ? handleSendEmail
                                : handleConfirm
                            }
                            className='flex px-4 py-2 bg-[#2CA1C8] text-white rounded-md items-center hover:bg-[#256994] transition-colors'
                          >
                            Continue
                            <svg
                              className='w-5 h-5 ml-3'
                              fill='none'
                              stroke='currentColor'
                              viewBox='0 0 24 24'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M14 5l7 7m0 0l-7 7m7-7H3'
                              ></path>
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>

                    <div
                      className={
                        step.stepName === 'Select Modality' ? 'grid  gap-4' : ''
                      }
                    >
                      {step.stepName === 'Select Modality' && (
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                          {checkboxes?.map((checkbox, index) => (
                            <div
                              key={index}
                              className={
                                'p-4 border rounded-xl cursor-pointer transition-all bg-white'
                              }
                              // className={`p-4 border rounded-xl cursor-pointer transition-all ${checkbox.checked ? 'border-[#2CA1C8]' : 'border-gray-200 hover:border-gray-300'
                              //   }`}
                            >
                              <div className='flex items-center justify-between'>
                                <div className='flex items-center gap-3'>
                                  <div
                                    className={`w-5 h-5 border-2 flex items-center justify-center cursor-pointer ${
                                      checkbox.checked
                                        ? 'bg-[#2CA1C8] border-[#2CA1C8]'
                                        : 'bg-white border-gray-400'
                                    }`}
                                    onClick={() =>
                                      handleCheckboxChanges(checkbox.id)
                                    }
                                  >
                                    {checkbox.checked && (
                                      <svg
                                        className='w-3 h-3 text-white'
                                        fill='none'
                                        stroke='currentColor'
                                        viewBox='0 0 24 24'
                                      >
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth='3'
                                          d='M5 13l4 4L19 7'
                                        />
                                      </svg>
                                    )}
                                  </div>
                                  <h3 className='text-lg font-semibold text-gray-800'>
                                    {checkbox.name}
                                  </h3>
                                </div>
                              </div>

                              {checkbox.checked && (
                                <div
                                  className={checkbox.sub_option ? 'mt-2' : ''}
                                >
                                  {checkbox.sub_option
                                    ? checkbox.pairs?.map((pair, pairIndex) => (
                                        <div key={pairIndex} className='mb-2'>
                                          <div className='flex gap-2 items-center'>
                                            <div className='flex-1'>
                                              <Select
                                                style={{
                                                  width: '100%',
                                                  border: '1px solid #d0d5dd', // Changed from red to #d0d5dd
                                                  outline: 'none',
                                                  color: pair.value1
                                                    ? 'black'
                                                    : '#667085' // Black when selected, #667085 when placeholder
                                                }}
                                                value={pair.value1}
                                                onChange={e =>
                                                  handleChange(
                                                    checkbox.id,
                                                    pairIndex,
                                                    'value1',
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option
                                                  value=''
                                                  style={{ color: '#667085' }}
                                                >
                                                  Select Category
                                                </option>
                                                {checkbox.sub_option?.map(
                                                  (option, idx) => (
                                                    <option
                                                      key={idx}
                                                      value={option.label}
                                                      style={{ color: 'black' }}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  )
                                                )}
                                              </Select>
                                            </div>
                                            <div className='flex-1'>
                                              {pair.value1 !== 'Other' ? (
                                                <Select
                                                  style={{
                                                    width: '100%',
                                                    border: '1px solid #d0d5dd', // Changed from red to #d0d5dd
                                                    outline: 'none',
                                                    color: pair.value2
                                                      ? 'black'
                                                      : '#667085' // Black when selected, #667085 when placeholder
                                                  }}
                                                  value={pair.value2}
                                                  onChange={e =>
                                                    handleChange(
                                                      checkbox.id,
                                                      pairIndex,
                                                      'value2',
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option
                                                    value=''
                                                    style={{ color: '#667085' }}
                                                  >
                                                    Select Sub-Category
                                                  </option>
                                                  {pair.value1 &&
                                                    checkboxes
                                                      .find(
                                                        c =>
                                                          c.id === checkbox.id
                                                      )
                                                      ?.sub_option.find(
                                                        option =>
                                                          option.label ===
                                                          pair.value1
                                                      )
                                                      ?.sub_options?.map(
                                                        (option, idx) => (
                                                          <option
                                                            key={idx}
                                                            value={option.value}
                                                            style={{
                                                              color: 'black'
                                                            }}
                                                          >
                                                            {option.value}
                                                          </option>
                                                        )
                                                      )}
                                                </Select>
                                              ) : (
                                                <input
                                                  type='text'
                                                  value={pair.value2}
                                                  onChange={e =>
                                                    handleChange(
                                                      checkbox.id,
                                                      pairIndex,
                                                      'value2',
                                                      e.target.value
                                                    )
                                                  }
                                                  className='w-full py-2 px-2 border rounded-lg border-[#d0d5dd]' // Changed from border-red-500 to border-[#d0d5dd]
                                                  placeholder='Specify...'
                                                />
                                              )}
                                            </div>
                                            {/* Add/Delete Pair Icons */}
                                            {pairIndex === 0 && (
                                              <svg
                                                width='40'
                                                height='40'
                                                viewBox='0 0 40 40'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                                onClick={() =>
                                                  !pair.value1 || !pair.value2
                                                    ? null
                                                    : handleAddPair(checkbox.id)
                                                }
                                                className='cursor-pointer'
                                              >
                                                <rect
                                                  width='40'
                                                  height='40'
                                                  rx='20'
                                                  fill='#DDF0F6'
                                                />
                                                <path
                                                  d='M28.8008 20C28.8008 20.44 28.4408 20.8 28.0008 20.8H20.8008V28C20.8008 28.4401 20.4408 28.8 20.0008 28.8C19.5608 28.8 19.2008 28.4401 19.2008 28V20.8H12.0008C11.5608 20.8 11.2008 20.44 11.2008 20C11.2008 19.56 11.5608 19.2 12.0008 19.2H19.2008V12C19.2008 11.56 19.5608 11.2 20.0008 11.2C20.4408 11.2 20.8008 11.56 20.8008 12V19.2H28.0008C28.4408 19.2 28.8008 19.56 28.8008 20Z'
                                                  fill='#2CA1C8'
                                                />
                                              </svg>
                                            )}
                                            {pairIndex !== 0 && (
                                              <svg
                                                width='40'
                                                height='40'
                                                viewBox='0 0 40 40'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                                onClick={() =>
                                                  handleDeletePair(
                                                    checkbox.id,
                                                    pairIndex
                                                  )
                                                }
                                                className='cursor-pointer'
                                              >
                                                <rect
                                                  width='40'
                                                  height='40'
                                                  rx='20'
                                                  fill='#DDF0F6'
                                                />
                                                <path
                                                  d='M28.8008 20C28.8008 20.44 28.4408 20.8 28.0008 20.8H12.0008C11.5608 20.8 11.2008 20.44 11.2008 20C11.2008 19.56 11.5608 19.2 12.0008 19.2H28.0008C28.4408 19.2 28.8008 19.56 28.8008 20Z'
                                                  fill='#2CA1C8'
                                                />
                                              </svg>
                                            )}
                                          </div>
                                        </div>
                                      ))
                                    : null}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      {/* OLD CODE
                      {step.stepName === 'Select Modality' && (
                        <div>
                          <label htmlFor='packageDropdown'>
                            Select a Package:
                          </label>
                          <select
                            id='packageDropdown'
                            value={selectedPackage}
                            onChange={handlePackageChange}
                            style={{ width: 250, marginLeft: 10 }}
                            className='py-2 my-3 px-2 w-full rounded-lg border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow'
                          >
                            <option value=''>Select Package</option>
                            {userData &&
                              userData.packages &&
                              userData?.packages.map((pkg, index) => (
                                <option key={index} value={pkg?.title}>
                                  {pkg?.title}
                                </option>
                                
                              ))}
                          </select>

                          {selectedPackage && (
                            <div style={{ marginTop: '10px' }}>
                              {userData &&
                                userData.packages &&
                                userData.packages
                                  .filter(pkg => pkg.title === selectedPackage)
                                  .map((pkg, index) => (
                                    <div key={index}>
                                      <p>{pkg?.description}</p>{' '}
                                    </div>
                                  ))}
                            </div>
                          )}
                        </div>
                      )} */}

                      {step.stepName === 'Select Modality' && (
                        <div className='space-y-4'>
                          <h2 className='text-2xl font-bold text-gray-800 mb-2'>
                            My Packages
                          </h2>

                          {/* Package Cards in Flex Row */}
                          <div className='flex flex-wrap gap-4'>
                            {userData?.packages?.map((pkg, index) => (
                              <div
                                key={index}
                                className={`relative p-2 border rounded-lg w-64 cursor-pointer transition-all
            border-gray-200 hover:border-gray-300`}
                                onClick={() =>
                                  handlePackageChange({
                                    target: { value: pkg.title }
                                  })
                                }
                              >
                                {/* Blue Checkmark Circle (Top-Right) */}
                                {selectedPackage === pkg.title ? (
                                  <div className='absolute top-2 right-2 w-6 h-6 bg-[#2CA1C8] rounded-full'></div>
                                ) : (
                                  <div className='absolute top-2 right-2 w-6 h-6 border-2 border-[#C2C2C2] rounded-full'></div>
                                )}

                                {/* Package Title */}
                                <h3 className='text-lg font-medium text-gray-800 mb-2'>
                                  {pkg.title}
                                </h3>

                                {/* Package Description (Clamped to 3 lines) */}
                                <p className='text-gray-600 line-clamp-3 text-lg font-normal'>
                                  {pkg.description}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* safty */}
                      {step.stepName === 'Safety' && (
                        <>
                          {/* Continue Button moved to top */}
                          {/* <div className='flex justify-end mb-6 gap-4'>
                            {currentStep > 1 && (
                              <button
                                type='button'
                                onClick={handlePreviousStep}
                                className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'
                              >
                                Previous
                              </button>
                            )}
                            <button
                              type='button'
                              onClick={handleSendEmail}
                              className='flex px-4 py-2 bg-[#2CA1C8] text-white rounded-md items-center '
                            >
                              Continue <svg className="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                              </svg>
                            </button>
                          </div> */}
                          {/* <div className='flex justify-end mb-6'>
                            <button
                              type='button'
                              onClick={handleSendEmail}
                              className='flex px-4 py-2 bg-[#2CA1C8] text-white rounded-md items-center mt-[-50px]'
                            >
                              Continue
                              <svg className="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                              </svg>
                            </button>
                          </div> */}
                          {/* Safety Check Card */}
                          <div className='bg-white p-6 rounded-lg shadow-sm  mb-6'>
                            <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                              Safety check
                            </h3>
                            <p className='text-gray-600 mb-6'>
                              As recommended by the MHRA, the referring
                              clinician is required to assess patient safety for
                              MRI scans. Cardiac pacemakers, artificial heart
                              valves, cochlear implants, cerebral aneurysm clips
                              are contra-indicated for MRI.
                            </p>

                            {/* Button-style Radio Groups */}
                            <div className='flex justify-between'>
                              {step.labels?.map((label, labelIndex) => {
                                const fieldName = step.fields[labelIndex]
                                return (
                                  <div
                                    key={labelIndex}
                                    className='w-full bg-[#F9FAFB] rounded-lg mr-4 py-5'
                                  >
                                    <div className='text-center'>
                                      <label className='block text-sm font-medium text-gray-700 mb-3'>
                                        {label}
                                        <span className='text-red-500'>*</span>
                                      </label>
                                    </div>

                                    <div className='flex justify-center gap-3'>
                                      {['Yes', 'No'].map(option => (
                                        <button
                                          type='button'
                                          key={option}
                                          onClick={() =>
                                            handleInputChange({
                                              target: {
                                                name: fieldName,
                                                value: option
                                              }
                                            })
                                          }
                                          className={`px-10 py-1 rounded-md border-2 transition-all border-[#2CA1C8] 
                ${
                  formData[fieldName] === option
                    ? 'bg-[#2CA1C8] text-white'
                    : 'bg-white text-[#2CA1C8]'
                }`}
                                        >
                                          {option}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          {/* Contrast investigations */}
                          <div className='bg-white p-6 rounded-lg shadow-sm  mb-6'>
                            <h3 className='text-lg font-semibold mb-4'>
                              Contrast investigations
                            </h3>
                            <p className='text-gray-600 mb-6'>
                              For patients above 65 years of age or with any
                              known problems with kidney function, serum
                              creatinine level or eGFR must be available prior
                              to imaging.
                            </p>

                            <div className='grid grid-cols-2 gap-4'>
                              <div className='bg-[#F9FAFB] rounded-lg mr-4 p-5 text-center'>
                                <label className='block text-sm font-medium text-gray-700 mb-2'>
                                  Serum creatinine/eGFR reading
                                </label>
                                <input
                                  type='number'
                                  name='eGFR'
                                  value={formData.eGFR || ''}
                                  onChange={e =>
                                    handleInputTextChange(
                                      'eGFR',
                                      e.target.value
                                    )
                                  }
                                  className='w-full px-3 py-2 border rounded-md focus:ring-1 focus:ring-[#2CA1C8] focus:border-[#2CA1C8]'
                                  placeholder='Serum number...'
                                  style={{ textAlign: 'center' }}
                                />
                              </div>
                              <div className='bg-[#F9FAFB] rounded-lg mr-4 p-3 text-center'>
                                <label className='block text-sm font-medium text-gray-700 mb-2'>
                                  Date taken
                                </label>
                                <input
                                  type='date'
                                  name='date'
                                  value={formData.date}
                                  onChange={e =>
                                    handleInputTextChange(
                                      'date',
                                      e.target.value
                                    )
                                  }
                                  className='w-full px-3 py-2 border rounded-md focus:ring-1 focus:ring-[#2CA1C8] focus:border-[#2CA1C8] text-center text-[#667085]'
                                />
                              </div>
                            </div>
                          </div>
                          {/* Others */}
                          <div className='bg-white p-6 rounded-lg shadow-sm '>
                            <h3 className='text-lg font-semibold mb-4'>
                              Others
                            </h3>
                            <div className='grid grid-cols-2 gap-4'>
                              <div>
                                <label className='block text-sm font-medium text-gray-700 mb-2'>
                                  Pregnancy
                                  <span className='text-red-500'>*</span>
                                </label>

                                <select
                                  type='text'
                                  name='pregnancyStatus'
                                  className='w-full px-3 py-2 border rounded-md focus:ring-1 focus:ring-[#2CA1C8] focus:border-[#2CA1C8]'
                                  onChange={handleInputChange}
                                  value={formData.pregnancyStatus || ''}
                                >
                                  <option value='' disabled>
                                    Select pregnancy
                                  </option>
                                  <option value='Yes'>Yes</option>
                                  <option value='No'>No</option>
                                  <option value='Not applicable'>
                                    Not applicable
                                  </option>
                                  <option value='Unknown'>Unknown</option>
                                </select>
                                {errors.pregnancyStatus && (
                                  <p className='text-red-500 text-sm mt-1'>
                                    {errors.pregnancyStatus}
                                  </p>
                                )}
                              </div>
                              <div>
                                <label className='block text-sm font-medium text-gray-700 mb-2'>
                                  Mobility
                                  <span className='text-red-500'>*</span>
                                </label>

                                <select
                                  type='text'
                                  name='mobility'
                                  onChange={handleInputChange}
                                  className='w-full px-3 py-2 border rounded-md focus:ring-1 focus:ring-[#2CA1C8] focus:border-[#2CA1C8]'
                                  value={formData.mobility || ''}
                                >
                                  <option value='' disabled>
                                    Select Mobility
                                  </option>
                                  <option value='Mobile'>Mobile</option>
                                  <option value='Non-Mobile'>Non-mobile</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {/* <p className='font-semibold my-2'>
                            1. Safety check (as recommended by the MHRA, the
                            referring clinician is required to assess patient
                            safety for MRI scans).
                          </p>
                          <p className='my-2'>
                            Cardiac pacemakers, artificial heart valves,
                            cochlear implants, cerebral aneurysm clips are
                            contra-indicated for MRI.
                          </p> */}
                        </>
                      )}

                      {step.stepName === 'Finish' && (
                        // <div className="fixed inset-0 flex justify-center items-center z-20 ml-auto w-[calc(100%-15%)]">
                        //   <div className="bg-[#F9FAFB] rounded-xl p-4 max-w-2xl w-full shadow-lg">
                        //     {/* Success Header */}
                        //     <div className="text-center mb-6">
                        //       <div className="text-3xl font-semibold text-[#000000] mb-4">Success!</div>
                        //     </div>

                        //     {/* Success Content */}
                        //     <div className="text-center mb-8">
                        //       <p className="text-gray-600 mb-6">
                        //         Your referral has been created and a copy has been emailed to you.
                        //         A confirmation email has also been sent to the patient. Our bookings
                        //         team will be in touch with the patient directly. You can also find this referral under your referrals page.
                        //       </p>
                        //       {/* Download Section */}
                        //       <div className="space-y-4 mb-8 ">
                        //         {pdfurls?.map((data, index) => (
                        //           <div key={index} className="flex justify-between items-center bg-white p-4 rounded-xl shadow-lg">
                        //             <div className="text-[#000000] font-semibold">
                        //               Download Referral for {clinicalData?.[index]}
                        //             </div>
                        //             <a
                        //               href={data}
                        //               target="_blank"
                        //               rel="noopener noreferrer"
                        //               className="px-4 py-2 border border-1 border-[#2285a3] bg-white text-[#2285a3] rounded-md hover:bg-[#2285a3] hover:text-white hover:border-white transition-colors whitespace-nowrap"
                        //             >
                        //               Download Now
                        //             </a>
                        //           </div>
                        //         ))}
                        //       </div>

                        //       {/* Back to Home Button */}
                        //       <button
                        //         onClick={() => navigate('/welcome')}
                        //         className="px-8 py-2 w-full text-[#ffffff] rounded-lg bg-[#2CA1C8] hover:bg-[#2285a3] transition-colors"
                        //       >
                        //         Return to Main Page
                        //       </button>
                        //     </div>

                        //   </div>
                        // </div>
                        <div className='w-2/3 text-center m-auto bg-[#F9FAFB] p-6 rounded-xl shadow-lg'>
                          {/* header */}
                          <div className='text-center mb-6'>
                            <div className='text-3xl font-semibold text-[#000000] mb-4'>
                              Success!
                            </div>
                          </div>

                          <div>
                            <p className='text-gray-600 mb-6'>
                              Your referral has been created and a copy has been
                              emailed to you. A confirmation email has also been
                              sent to the patient. Our bookings team will be in
                              touch with the patient directly. You can also find
                              this referral under your referrals page.
                            </p>
                            {/* Download Section */}
                            <div className='space-y-4 mb-8 max-h-72 overflow-auto'>
                              {pdfurls?.map((data, index) => {
                                const prefix = clinicalData?.[index]
                                  .split('-')[0]
                                  .trim()
                                const matchedPackage = userData?.packages.find(
                                  pkg => pkg.title === prefix
                                )
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className='flex justify-between items-center bg-white p-4 rounded-xl shadow-lg'
                                    >
                                      <div className='text-[#000000] font-semibold'>
                                        Download Referral for{' '}
                                        {matchedPackage
                                          ? prefix
                                          : clinicalData?.[index]}
                                      </div>
                                      <a
                                        href={data}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='px-4 py-2 border border-1 border-[#2285a3] bg-white text-[#2285a3] rounded-md hover:bg-[#2285a3] hover:text-white hover:border-white transition-colors whitespace-nowrap'
                                      >
                                        Download Now
                                      </a>
                                    </div>
                                  </>
                                )
                              })}
                            </div>
                            {/* Back to Home Button */}
                            <button
                              onClick={() => navigate('/welcome')}
                              className='px-8 py-2 w-full text-[#ffffff] rounded-lg bg-[#2CA1C8] hover:bg-[#2285a3] transition-colors'
                            >
                              Return to Main Page
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    {step.stepName === 'Select Modality' && (
                      <>
                        <div className='flex justify-between gap-6 w-full mt-3 border-1 container'>
                          <div className='mb-4 w-full'>
                            <label className='block text-xl font-semibold text-gray-700'>
                              Clinical Indication{' '}
                              <span className='text-red-500'>*</span>
                            </label>
                            <Textarea
                              onChange={e =>
                                handleInputTextChange(
                                  'clinicalIndication',
                                  e.target.value
                                )
                              }
                              type='text'
                              name='clinicalIndication'
                              value={formData.clinicalIndication}
                              className='mt-1 p-2 w-full border rounded-md bg-transparent focus:border-sky-blue-700 focus:outline-sky-blue-700 shadow'
                              placeholder='Add descriptions....'
                            />
                            {errors[currentStep] &&
                              errors[currentStep][step.field] && (
                                <p className='text-red-500 text-sm mt-1'>
                                  {errors[currentStep][step.field]}
                                </p>
                              )}
                          </div>
                        </div>
                      </>
                    )}

                    {/* Summary */}
                    <div className=' rounded-xl'>
                      {step.stepName === 'Summary' && (
                        <>
                          {/* <div className='flex justify-end  '>
                            <button
                              type='button'
                              onClick={handleConfirm}
                              className='flex px-4   bg-[#2CA1C8] text-white rounded-md items-center mt-[-50px]'
                            >
                              Continue
                              <svg className="w-5 h-5 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                              </svg>
                            </button>
                          </div> */}

                          {/* 1. Modality + Clinical Indication - ADJACENT */}
                          <div className='grid grid-cols-2 gap-4 mb-6 mt-10'>
                            {/* Modality Card */}
                            <div className='bg-white p-6 rounded-lg shadow-sm '>
                              <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                                Modality
                              </h3>
                              <div className='space-y-2'>
                                {console.log('checkbox', checkboxes)}

                                {checkboxes
                                  ?.filter(data => data.checked)
                                  ?.map(data =>
                                    data.pairs && data.pairs.length > 0 ? (
                                      data.pairs.map(pair => (
                                        <p
                                          key={`${data.id}-${pair.value1}`}
                                          className='text-gray-700'
                                        >
                                          {`${data.name} > ${pair.value1} > ${pair.value2}`}
                                        </p>
                                      ))
                                    ) : (
                                      <p
                                        key={data.id}
                                        className='text-gray-700'
                                      >
                                        {data.name}
                                      </p>
                                    )
                                  )}
                                {userData?.packages
                                  ?.filter(
                                    pkg => pkg?.title === selectedPackage
                                  )
                                  ?.map(pkg => (
                                    <p
                                      key={pkg.title}
                                      className='text-gray-700'
                                    >
                                      {`${pkg.title} - ${pkg.description}`}
                                    </p>
                                  ))}
                              </div>
                            </div>

                            {/* Clinical Indication Card */}
                            <div className='bg-white p-6 rounded-lg shadow-sm '>
                              <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                                Clinical Indication
                              </h3>
                              <p className='text-gray-700'>
                                {formData.clinicalIndication}
                              </p>
                              {errors[currentStep] &&
                                errors[currentStep]['clinicalIndication'] && (
                                  <p className='text-red-500 text-sm mt-1 font-medium'>
                                    {errors[currentStep]['clinicalIndication']}
                                  </p>
                                )}
                            </div>
                          </div>

                          {/* 2. Safety Check - Split into 2 cards */}
                          <div className='mb-6'>
                            <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                              Safety Check
                            </h3>
                            <div className='grid grid-cols-2 gap-4'>
                              {/* Metal Implant Card */}
                              <div className='bg-white p-6 rounded-lg shadow-sm '>
                                <div className=''>
                                  <p className='text-gray-700 text-gray-700 font-medium'>
                                    Does the patient have a metal implant or
                                    pacemaker?
                                  </p>
                                  <p className=' mt-5'>
                                    {formData.metalImplant || 'N/A'}
                                  </p>
                                </div>
                              </div>

                              {/* Eye Injury Card */}
                              <div className='bg-white p-6 rounded-lg shadow-sm  '>
                                <div className=''>
                                  <p className='text-gray-700 text-gray-700 font-medium'>
                                    Has the patient ever had an injury to the
                                    eye involving a metallic object?
                                  </p>
                                  <span className='text-gray-700  mt-5'>
                                    {formData.eyeInjury || 'N/A'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* 3. Mobility + Pregnancy - ADJACENT */}
                          <div className='grid grid-cols-2 gap-4 mb-6'>
                            {/* Mobility Card */}
                            <div className='bg-white p-6 rounded-lg shadow-sm  '>
                              <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                                Mobility
                              </h3>
                              <p className='text-gray-700 '>
                                {formData.mobility || 'N/A'}
                              </p>
                            </div>

                            {/* Pregnancy Card */}
                            <div className='bg-white p-6 rounded-lg shadow-sm  '>
                              <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                                Pregnancy
                              </h3>
                              <p className='text-gray-700 '>
                                {formData.pregnancyStatus || 'N/A'}
                              </p>
                            </div>
                          </div>
                          {/* 4. Contrast Investigations */}
                          <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                            Contrast investigations
                          </h3>
                          <div className='grid grid-cols-2 gap-4 mb-6'>
                            {/* Serum creatinine/eGFR reading */}
                            <div className='bg-white p-6 rounded-lg shadow-sm  '>
                              <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                                Serum creatinine/eGFR reading
                              </h3>
                              <p className='text-gray-700 '>
                                {formData.eGFR || 'N/A'}
                              </p>
                            </div>

                            {/* Date taken */}
                            <div className='bg-white p-6 rounded-lg shadow-sm  '>
                              <h3 className='text-lg font-semibold mb-4 text-[#344054]'>
                                Date taken
                              </h3>
                              <p className='text-gray-700 '>
                                {formData.date
                                  ? formatDate(formData.date)
                                  : 'N/A'}
                              </p>
                            </div>
                          </div>

                          {/* 4. Contrast Investigations - Keep existing grid */}
                          {/* <div className='bg-white p-6 rounded-lg shadow-sm border border-gray-200 mb-6'>
        <h3 className='text-lg font-semibold mb-4 text-[#344054]'>Contrast Investigations</h3>
        <div className='grid grid-cols-2 gap-4'>
                              <div>
                                <p className='text-gray-700'>Serum creatinine/eGFR reading</p>
                                <p className='text-gray-700 font-medium'>{formData.eGFR || 'N/A'}</p>
                              </div>
                              <div>
                                <p className='text-gray-700'>Date taken</p>
                                <p className='text-gray-700 font-medium'>
                                  {formData.date ? formatDate(formData.date) : 'N/A'}
                                </p>
                              </div>
                            </div>
      </div> */}
                        </>
                      )}
                    </div>

                    {/* old code of buttons which were at bottom but moved to top  */}
                    {/* <div className='mt-4 flex justify-end'>
                      {currentStep > 2 && currentStep !== steps.length && (
                        <button
                          type='button'
                          onClick={() => handleEdit(rid)}
                          className='px-4 mx-4 py-2 ml-4 bg-white text-black border border-black rounded-md'
                        >
                          Edit referral
                        </button>
                      )}

                      {currentStep > 2 && currentStep !== steps.length && (
                        <button
                          type='button'
                          onClick={handleConfirm}
                          className='flex px-4 py-2 bg-[#003942] text-white rounded-md pd-30'
                        >
                          Confirm referral
                        </button>
                      )}
                    </div>
                    <div className='mt-4 flex justify-end'>
                      {currentStep < steps.length &&
                        step.stepName === 'Safety' && (
                          <button
                            type='button'
                            onClick={handlePreviousStep}
                            className='px-4 mx-4 py-2 ml-4 bg-white text-black border border-black rounded-md'
                          >
                            Previous
                          </button>
                        )}
                      {currentStep < steps.length &&
                        step.stepName === 'Select Modality' && (
                          <>
                            <div className='mt-4 flex justify-end me-5'>
                              <button
                                type='button'
                                onClick={handleCancel}
                                className='px-4 py-2 bg-[#003942] text-white rounded-md'
                              >
                                Cancel
                              </button>
                              <button
                                type='button'
                                onClick={handleNextStep}
                                className=' flex px-4 ms-4 py-2 bg-[#003942] text-white rounded-md'
                              >
                                Continue to Safety Checks
                                <div></div>
                              </button>
                            </div>
                          </>
                        )}
                      {currentStep < steps.length &&
                        step.stepName === 'Safety' && (
                          <button
                            type='button'
                            onClick={handleSendEmail}
                            className=' flex px-4 py-2 bg-[#003942] text-white rounded-md pd-30'
                          >
                            Proceed to Confirmation
                          </button>
                        )}
                    </div> */}
                  </div>
                ))}
              </form>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default MultiStepForm
