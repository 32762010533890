import React, { useEffect, useState } from "react";
import Left from "../Assets/images/Left.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../Assets/images/logomin.png";
import { CiMail } from "react-icons/ci";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import axios from "../config/axios";
import Footer from "../components/Footer";
import './footer.css'
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import animationData from '../Assets/loader.json';
import image from '../Assets/images/Group 11119.png';



const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFooterVisible, setFooterVisibility] = useState(false);
  const handleIconClick = () => {
    setFooterVisibility(!isFooterVisible);
    console.log("Working");

  };


  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast("Please enter your email and password to continue.");
      return;
    }
    if (password.length < 8) {
      toast("Password should not be less than 8 characters.");
      return;
    }
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      toast("Please enter a valid email adress.");
      return;
    }
    try {
      setLoading(true)
      const response = await axios.post("users/login", {
        email,
        password,
      }, {
        headers: {
          "Origin": "Access-Control-Allow-Origin",
        }
      },);
      if (response.status === 200) {
        sessionStorage.setItem("password", password);
        sessionStorage.setItem("data", JSON.stringify(response.data.data));
        setEmail("");
        setPassword("");
        setTimeout(() => {
          if (response?.data?.data?.userData?.role === "Admin") {
            navigate("/consultants")
            window.location.reload();
            toast(response?.data?.message);
            setLoading(false)
          } else {
            sessionStorage.setItem("data", JSON.stringify(response.data.data));
            navigate(
              response?.data?.data?.userData?.jobTitle === ""
                ? "/profile"
                : "/welcome"
            )
            window.location.reload();
            toast(response?.data?.message);
            setLoading(false)
          }
        }, 2000);
      } else {
        setLoading(false)
        console.log("error")
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.status === 402) {
        setTimeout(
          () =>
            navigate("/Otp-verification", {
              state: {
                data: email,
              },
            }),
          2000
        );
        toast(error?.response?.data?.message);
      } else {
        toast(error?.response?.data?.message);

        if (error?.response?.data?.message === "Please verify your account by inputting the OTP you have received.") {
          setTimeout(
            () =>
              navigate("/Otp-verification", {
                state: {
                  data: email,
                },
              }),
            2000
          );
        }
      }
    }
  };
  return (
    <>
      {
        loading ? (
          <>
            <div className="p-36  flex justify-center items-center mt-20">
              <Lottie animationData={animationData} />
            </div>
          </>
        ) : (
          <div
            className="flex w-screen h-screen overflow-hidden p-3"
            style={{ backgroundColor: "#F9FAFB", position: 'fixed' }}
          >
            <ToastContainer />
            <div
              className="w-1/2 h-full  flex items-center justify-center relative"
              style={{
                backgroundImage: `url(${Left})`,
                backgroundSize: "cover",
                borderRadius: "16px",
              }}
            >
              <>


              </>
            </div>

            <div className="w-1/2 mx-auto h-full flex items-center justify-center">

              <div className="text-center text-white p-4">
              </div>
              {/* Example: Adding the icon to trigger handleIconClick */}

              <div className="absolute right-10 bottom-[20px] pl-[5px] hover:shake z-50">
                <img
                  src={image}
                  alt="Click Icon"
                  onClick={handleIconClick}
                  className="cursor-pointer w-[50px]"
                />
              </div>

              <div className="h-full w-full relative">

                <div className="flex justify-end mt-6 mr-14">
                  <Link to='/signup'>
                    <img
                      className="hover:scale-105 transition duration-300"
                      src={logo}
                      alt="Logo"
                      width="200px"
                      height="200px"
                    />
                  </Link>
                </div>
                {/* <div className="h-full flex flex-col justify-center items-center"> */}
                <div className="h-full mt-36">
                  {/* <div className="text-[30px] text-gray-900 font-bold flex justify-center">
                    UME Health{" "}
                    <span className="text-[30px] text-[#2CA1C8] font-bold flex "> Referrals Portal{" "}
                    </span>{" "}
                  </div> */}
                  <div className="text-[30px] text-gray-900 font-bold flex justify-center">
  UME Health <span className="text-[#2CA1C8] ml-2">Referrals Portal</span>
</div>

                  <div className="p-4 mx-auto rounded-xl hover:scale-105 transition duration-300 flex justify-center w-full items-center"  >

                    <div
                      className="mx-auto flex flex-col  justify-center bg-white  rounded-xl p-5  hover:scale-105 transition duration-300"
                      style={{
                        // borderTopColor: "#003942",
                        // borderBottomColor: "#003942",
                        width: "400px",
                        paddingBottom: "10px",
                      }}
                    >
                      {/* <div className='text-2xl text-gray-500 font-bold flex justify-center'>Login to your existing account</div> */}
                      <form>
                        <label className="block mb-4 pt-3">
                          <span className="font-medium mb-2 text-[#344054]">
                            {" "}
                            Email
                          </span>
                          <div className="flex relative items-center ">
                            {/* <CiMail className=" absolute left-3 w-6 h-6 mr-2 text-gray-400 hover:shake" /> */}
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="pl-3 w-full p-2 py-3 border bg-white focus:bg-gray-200 rounded-md focus:border-sky-600 focus:outline-sky-600 bg-opacity-80"
                              placeholder="you@workemail.com"
                            />
                          </div>
                        </label>
                        <label className="block mb-2">
                          <span className="font-medium mb-2 text-[#344054]">
                            {" "}
                            Password
                          </span>
                          <div className="flex relative items-center ">
                            {showPass ? (
                              <FaEyeSlash
                                className=" absolute right-1 w-6 h-6 mr-2 text-gray-400 hover:shake"
                                onClick={() => setShowPass(!showPass)}
                              />
                            ) : (
                              <FaEye
                                className=" absolute right-1 w-6 h-6 mr-2 text-gray-400 hover:shake"
                                onClick={() => setShowPass(!showPass)}
                              />
                            )}

                            {/* <CiLock className=" absolute left-4 w-6 h-6 mr-2 text-gray-400 hover:shake" /> */}
                            <input
                              type={showPass ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="w-full p-2 py-3 pl-3 border bg-white focus:bg-gray-200 rounded-md focus:border-sky-600 focus:outline-sky-600 bg-opacity-80"
                              placeholder="Minimum 8 characters"
                            />
                          </div>
                        </label>
                        <button
                          onClick={(e) => handleLogin(e)}
                          className="flex justify-center mx-auto rounded-xl px-4 py-2 my-3 overflow-hidden relative group cursor-pointer border font-medium text-[16px] border-cyan-600 w-full text-red-100 hover:scale-105 duration-[900ms] bg-cyan-600"
                        >
                          <span className="absolute w-64 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-50 bg-sky-700 top-3/5 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                          <span className="relative text-white transition duration-[900ms] group-hover:text-white ease">
                            Login
                          </span>
                        </button>
                      </form>
                      <p className="flex items-center justify-center">
                        <p className="pe-2 text-black text-sm">
                          Don't have an account?
                        </p>
                        <p
                          className="font-semibold underline text-sm"
                          style={{ color: "#2E90FA" }}
                        >
                          <NavLink to="/signup">Sign up </NavLink>{" "}
                        </p>{" "}
                      </p>
                      <p className="flex items-center justify-center">
                        <p className=" pe-2 text-black text-sm">
                        {/* <p className=" text-center text-sm text-[#667085]"> */}
                          Forgot your password?{" "}
                        </p>
                        <p
                          className="font-semibold underline text-sm"
                          style={{ color: "#2E90FA" }}
                        >
                          <NavLink to="/forgot-password" className="ms-1">
                            Reset password
                          </NavLink>{" "}
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                {/* Footer Section */}
                <div className={`absolute left-36 bottom-12 w-full ${isFooterVisible ? 'slide-in' : ''}`}>
                  {isFooterVisible && (
                    <div className="flex justify-center">
                      <div className="w-full max-w-screen-xl"> {/* Add max width container */}
                        <Footer />
                      </div>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
        )
      }
    </>
  );
};

export default Login;
